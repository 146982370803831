/*

    Breadcrumbs
    --------------------------------------------------

    Table of Contents
    --------------------------------------------------
    :: 0.0 - #Containers
    :: 1.0 - #

*/

// --------------------------------------------------
// 1.0 - #Containers
// --------------------------------------------------

.breadcrumb {
  margin: @spacer / 2 0 0 0;
  list-style: none;
  font-size: 0;

  @media @desktop {
    margin: @spacer / 2 0 0 0;
  }
}

.breadcrumb-item {
  display: inline-block;
  margin-bottom: 0;
  font-size: @spacer / 4;
  + .breadcrumb-item:before {
    display: inline-block;
    padding-right: @spacer / 4;
    padding-left: @spacer / 4;
    color: @text-color;
    content: ">";
  }

  &.active {
    color: @text-color;
  }
}

.breadcrumb-item-link {
  font-size: @spacer / 4;
  text-decoration: none;
}