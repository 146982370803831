/*

    Page - Equipment List Pop Up
    --------------------------------------------------

    Table of Contents
    --------------------------------------------------
    :: 1.0 - #Modifiers
    :: 1.1 - #Containers

*/

//--------------------------------------------------
// 1.0 - #Modifiers
//--------------------------------------------------

.page-equipment-list-pop-up {
  .form-footer {
    .btn {
      width: 100%;
    }

    .btn-icon {
      width: auto;
    }
  }
}

//--------------------------------------------------
// 1.1 - #Containers
//--------------------------------------------------
