/*

    Page - Home
    --------------------------------------------------

    Table of Contents
    --------------------------------------------------
    :: 1.0 - #Modifiers
    :: 1.1 - #Containers
    :: 1.2 - #Section - Get A Quote
    :: 1.3 - #Section - Testimonials
    :: 1.4 - #Section - Get Started

*/


//--------------------------------------------------
// 0.0 - #Styleguide Definition
//--------------------------------------------------

//--------------------------------------------------
// 1.0 - #Modifiers
//--------------------------------------------------

.is-home {
  padding-top: 0;

  .nav {
    background-color: transparent;
    color: white;
    border-bottom: 0;
  }

  .nav-down {
    background-color: white;
    color: @text-color;
    .nav-line {
      background-color: @brand-secondary;
    }
    .nav-list-item-link {
      transition: none;
    }
  }

  .nav-up {
    background-color: white;
  }

  .nav-top {
    color: white;
    background-color: transparent;
    box-shadow: none;
    .nav-line {
      background-color: white !important;
    }
  }

  .nav-line {
    background-color: white;
  }
}

.page-wrap-home {
  padding-top: 0;
  padding-bottom: 0;
}

//--------------------------------------------------
// 1.2 - #Section - Quote
//--------------------------------------------------

.section-quote {
  min-height: 16.6666667rem;
  margin-left: auto;
  margin-right: auto;
  background: white;
  text-align: center;
  z-index: @zIndex-2;
  position: relative;
  padding: @spacerMobile 0;

  @media @desktop {
    width: @spacer * 14;
    border: 0.5rem solid @gray-lighter;
    padding: @spacer / 2;
    box-shadow: @z-depth-2;
    position: absolute;
    padding: @spacer /2;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    //animation: fade-and-slide 1s @timing-function forwards;
    //opacity: 0;
  }
}

.section-quote-title {
  .font-size(18,24,28);
  margin: 0 0 @spacer / 2 0;
  color: @text-color;
}

@keyframes fade-and-slide {
  0% {
    opacity: 0;
    transform: translate3d(-50%,-45%,0);
  }

  100% {
    opacity: 1;
    transform: translate3d(-50%,-50%,0);
  }
}

.form-group-radio-app {
  position: relative;
  margin-bottom: @spacer / 2;
  user-select: none;
  text-align: center;

  .form-control-radio-label {
    border-width: 1px;
    border-style: solid;
    border-color: @border-color;
    padding: @spacer / 4 @spacer / 4;
    border-radius: 2px;
    width: 100%;
    font-size: @spacer / 3.333333333;
    transition: all @duration @timing-function;
    height: @spacer * 2.6;
    display: flex;
    flex-direction: column;
  }

  .form-group-radio-copy {
    display: block;
    font-size: @spacer / 4;
    vertical-align: middle;
  }

  .form-control-radio-content-wrap {
    display: block;
    margin-bottom: @spacer / 4;
    font-weight: @bold;
  }

  .form-control-radio-copy {
    position: relative;
    top: 2px;
  }

  .form-control-radio {
    &:checked {
      + .form-control-radio-label {
        border-color: @brand-primary;
      }
    }
    &:hover {
      + .form-control-radio-label {
        border-color: @brand-primary;
      }
    }
  }

}

.quote-input-wrap {
  position: relative;
  height: 3.5rem;
  width: 15.444rem;
  margin: 0 auto;
  margin-bottom: @spacer / 4;
}

.quote-input-label {
  margin-bottom: 0.5rem;
  display: block;
}

.quote-icon {
  position: absolute;
  left: 1rem;
  top: 6px;
  color: @brand-secondary;
  font-weight: @bold;
}

.form-control-quote {
  height: 2.5rem;
  width: 15.444rem;
  border: 1px solid @border-color;
  border-radius: 2px;
  padding-left: 2rem;
  box-shadow: @input-inset-shadow;
  padding-right: 121px;
}

.btn-quote-submit {
  height: 2.5rem;
  position: absolute;
  right: 0;
  top: 0;
  background: @brand-primary;
  box-shadow: none;
  margin: 0;

  @media @desktop-max {
    width: 30%;

    .btn-copy {
      padding: 0;
    }
  }
}

.quote-annual-premium-copy,
.quote-estimate-value {
  color: @text-color-light;
}

.quote-estimate {
  font-size: @spacer;
  color: @brand-primary;
  font-weight: @bold;
}

.quote-links-wrap {
  margin-top: @spacer / 2;
  a:first-child {
    margin-right: 1rem;
  }
}

.quote-returned-disclaimer {
  color: @gray-dark;
  font-size: 0.8333334rem;
  padding-top: 1.5rem;
}

.quote-help-copy {
  font-size: @spacer / 4;
}

.home-error {
  margin: 0.5rem;
}

//--------------------------------------------------
// 1.0 - #Section - Testimonial
//--------------------------------------------------

.section-testimonial {
  background: @gray-lightest;
  width: 100%;
  text-align: center;
  position: relative;
  padding: @spacerMobile * 2 0;
  overflow: hidden;

  @media @desktop {
    padding-top: @spacer * 5;
    padding-bottom: @spacer * 2;
  }
}

.testimonial-header {
  color: @brand-secondary;
  margin: 0;
  z-index: @zIndex-2;
  position: relative;
}

.testimonial-copy {
  position: relative;

  &:before,
  &:after {
    font-size: @spacer * 1.2;
    position: absolute;
    color: @brand-primary;
    font-family: @font-header;
    top: 50%;
    transform: translate3d(0,-50%,0);
  }

  &:before {
    content: open-quote;
    left: -@spacer * 1.5;
  }

  &:after {
    content: close-quote;
    right: -@spacer * 1.5;
  }
}

.testimonial-author {
  font-size: 1.3888889rem;
  margin-bottom: 0;
  color: @gray-dark;
}

.testimonial-link {
  display: block;
  margin-bottom: @spacerMobile / 2;
  @media @desktop {
    margin-bottom: @spacer / 2;
  }
}

.section-testimonial-svg {
  position: absolute;
  left: 10%;
  top: 50%;
  height: 115%;
  transform: translate3d(0,-50%,0);
  fill: @gray-lighter;
  opacity: @opacity-40;
}

//--------------------------------------------------
// 1.0 - #Section - Testimonial Slider
//--------------------------------------------------

.swiper-container {
  overflow: hidden;
  margin-bottom: @spacerMobile;
}

.swiper-slide {
  opacity: 0 !important;
  padding: 0 @spacerMobile / 2;
  @media @desktop {
    padding: 0 @spacer * 3;
  }
}

.swiper-slide-active {
  opacity: 1 !important; // Swiper made me do it. Otherwise faded slides show behind if the slide has no background-color;
}

.swiper-pagination {
  text-align: center;
  font-size: 0;
  cursor: pointer;
  position: absolute;
  bottom: -@spacerMobile * 1.833333333;
  left: 50%;
  transform: translate3d(-50%,0,0);
  width: 100%;
  @media @desktop {
    margin: @spacer 0 0 0;
    bottom: -@spacer;
  }
}

.swiper-pagination-bullet {
  width: @spacer / 2;
  height: @spacer / 2;
  background-color: white;
  border-radius: 50%;
  border-width: 9px;
  border-style: solid;
  border-color: lighten(@border-color, 10%);
  display: inline-block;
  margin: 0 @spacer / 4;
  transition: all @duration @timing-function;
}

.swiper-pagination-bullet-active {
  background-color: @brand-primary;
  border-color: @brand-secondary;
}

//--------------------------------------------------
// 4.0 - #Section - Get Started
//--------------------------------------------------

.section-get-started {
  border-top: 0.5rem solid @brand-secondary;
  background: @brand-primary;
  padding: @spacerMobile * 2 0;
  text-align: center;
  position: relative;
  color: white;
  overflow: hidden;

  @media @desktop {
    padding: @spacer * 1.5 0;
    text-align: left;
  }

  .row {
    @media @desktop {
      display: flex;
      align-items: center;
    }
  }

}

.section-title-light {
  color: white;
}

.section-img-wrap {
  padding-right: @spacerMobile;
  padding-left: @spacerMobile;
  margin-bottom: @spacerMobile;
  @media @desktop {
    padding-left: @spacer * 2;
    margin-bottom: 0;
  }
}

.section-img {
  width: 100%;
  @media @desktop {
    width: auto;
  }
}

.mp-icon-notes {
  font-size: @spacer;
}
