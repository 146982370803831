/*

    Loader
    --------------------------------------------------

    Table of Contents
    --------------------------------------------------
    :: 1.0 - Containers
    :: 1.1 - Components
 
*/

// --------------------------------------------------
// 1.0 - Containers
// --------------------------------------------------

.loader-wrap { 
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  z-index: @zIndex-4--loader;
  background: white;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  transform: translate3d(0,0,0);
  color: @text-color-light;
}

.loader-wrap-content {
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  font-size: 0;
}

.loader-wrap-svg {
  animation: pulsate 3.5s linear infinite both;
  font-size: 150px;

  @media @desktop {
    font-size: 10vw;
  }
}

.loader-wrap-copy {
  .font-size(18,18,18);
  position: absolute;
  bottom: @spacer;
  left: 50%;
  transform: translate3d(-50%,0,0);
  margin: 0;
}

// --------------------------------------------------
// 1.3 - Animations
// --------------------------------------------------

@keyframes pulsate {
  0%   { transform: scale(1,1); opacity: 0.2; }
  50%  { transform: scale(1.05,1.05); opacity: 1; }
  100% { transform: scale(1,1); opacity: 0.2; }
}
