/*

    Alerts
    --------------------------------------------------

    Table of Contents
    --------------------------------------------------
    :: 1.0 - #Containers
    :: 1.1 - #Type
    :: 1.2 -

*/

//--------------------------------------------------
// 1.0 - #Containers
//--------------------------------------------------

.alert {
  display: flex;
  width: 100%;
  align-content: center;
  justify-content: center;
  padding: 0.5rem;

  ul {
    margin-bottom: 0;
  }
}

.alert-warning {
  background-color: @brand-warning-alpha;
}

.alert-error {
  background-color: @brand-danger-alpha;
  border: 1px solid @brand-danger;
}
