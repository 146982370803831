/*

    Variables
    --------------------------------------------------

    Table of Contents
    --------------------------------------------------
    :: 1.0 - #Color
    :: 1.1 - #Typography
    :: 1.2 - #Breakpoints
    :: 1.3 - #Container Sizes
    :: 1.4 - #Motion
    :: 1.5 - #Shadows

*/

//--------------------------------------------------
// 1.1 - #Styleguide Definitions
//--------------------------------------------------

/**
  * @stylesheet variables.less Variables
  * @parent Styles.scaffolding 1
  * @description
  * Core site scaffolding
  **/

/*

//--------------------------------------------------
// 1.0 - #Color
//--------------------------------------------------

/**
 * @styles color-palette Color Palette
 *
 * @description A list of all the colors used on this site.
 * @demo demos/scaffolding/color/demo.html
*/


@brand-primary:                   #059ced;
@brand-secondary:                 #024a71;

@gray-darker:                     #212121;
@gray-dark:                       #616161;
@gray:                            #9E9E9E;
@gray-light:                      #BDBDBD;
@gray-lighter:                    #E0E0E0;
@gray-lightest:                   #F5F5F5;

@hr-border:                       @gray-light;
@link-color:                      @brand-primary;
@link-hover-color:                darken(@brand-primary, 7%);
@table-row-active-bg:             rgba(2, 74, 113, 0.08);

@body-bg:                         white;
@text-color:                      @gray-darker;
@text-color-light:                @gray;

@modal-background:                rgbA(0,0,0,0.4);

@border-color:                    @gray-light;
@border-color-light:              @gray-lighter;

@brand-danger:                    #f44336;
@brand-warning:                   #FF9800;
@brand-success:                   #4CAF50;

@brand-danger-alpha:              rgba(244,67,44,@opacity-20);
@brand-info-alpha:                rgba(5,156,247,@opacity-10);
@brand-warning-alpha:             rgba(255, 152, 0, @opacity-50);

//--------------------------------------------------
// 1.1 - #Typography
//--------------------------------------------------

@spacerMobile:                    1.875rem;
@spacer:                          3.333333333rem;

@font-family-fallback:            'Helvetica', sans-serif;
@font-family-fallback-header:     'Rockwell', serif;

@font-header:                     'Bitter', serif;
@font-family-base:                'Roboto', sans-serif;

@font-size-base:                  16px;

@remSm:                           16;
@remMd:                           18;
@remLg:                           18;

@baseline:                        6;

@light:                           300;
@regular:                         400;
@medium:                          500;
@bold:                            700;
@black:                           800;

@line-height-base:                1.777777778;

@headings-font-family:            @font-header;
@headings-font-weight:            800;
@headings-line-height:            1.25;
@headings-color:                  white;

@line-height-large:               1.6111111112;
@line-height-small:               1.5;

@letter-spacing-40:               0.04em;
@letter-spacing-100:              0.10em;
@letter-spacing-140:              0.14em;

// --------------------------------------------------
// 1.2 - #Breakpoints
// --------------------------------------------------

@screen-xs:                       480px;
@screen-xs-min:                   @screen-xs;
@screen-phone:                    @screen-xs-min;

@screen-sm:                       768px;
@screen-sm-min:                   @screen-sm;
@screen-tablet:                   @screen-sm-min;

@screen-md:                       1025px;
@screen-md-min:                   @screen-md;
@screen-desktop:                  @screen-md-min;

@screen-lg:                       1280px;
@screen-lg-min:                   @screen-lg;
@screen-lg-desktop:               @screen-lg-min;

@screen-xl:                       1441px;
@screen-xl-min:                   @screen-xl;
@screen-xl-desktop:               @screen-xl-min;

@screen-xs-max:                   (@screen-sm-min - 1);
@screen-sm-max:                   (@screen-md-min - 1);
@screen-md-max:                   (@screen-lg-min - 1);
@screen-lg-max:                   (@screen-xl-min - 1);

@phone-xs:                        ~"only screen and (min-width: @{screen-xs})";
@phone-xs-max:                    ~"only screen and (max-width: @{screen-xs})";
@phone:                           ~"only screen and (min-width: @{screen-xs-max})";
@phone-landscape:                 ~"only screen and (min-device-width: 320px) and (orientation: landscape)";
@phone-5s:                        ~"only screen and (min-device-width: 320px) and (max-device-width: 568px)";
@phone-5s-min:                    ~"only screen and (min-device-width: 320px)";
@phone-5s-portrait:               ~"only screen and (min-device-width: 320px) and (max-device-width: 568px) and (orientation : portrait)";
@phone-5s-landscape:              ~"only screen and (min-device-width: 320px) and (max-device-width: 568px) and (orientation : landscape)";
@phone-6:                         ~"only screen and (min-device-width: 375px) and (max-device-width: 667px)";
@phone-6-portrait:                ~"only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation : portrait) ";
@phone-6-landscape:               ~"only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: landscape)";
@tablet-max:                      ~"only screen and (max-width: @{screen-xs-max})";
@tablet:                          ~"only screen and (min-width: @{screen-sm-min})";
@desktop:                         ~"only screen and (min-width: @{screen-md-min})";
@desktop-max:                     ~"only screen and (max-width: @{screen-md-min})";
@desktop-lg:                      ~"only screen and (min-width: @{screen-lg-min})";
@desktop-xl:                      ~"only screen and (min-width: @{screen-xl-min})";
@isIE:                            ~"all and (-ms-high-contrast: none), (-ms-high-contrast: active)  ";

@grid-columns:                    12;
@grid-gutter-width:               30px;

// --------------------------------------------------
// 1.3 - #Container Sizes
// --------------------------------------------------

// Define the maximum width of `.container` for different screen sizes.

@container-tablet-portrait:       100%;

@container-tablet:                100%;
@container-sm:                    @container-tablet;

@container-desktop:               768px;
@container-md:                    @container-desktop;

@container-large-desktop:         1024px;
@container-lg:                    @container-large-desktop;

@container-xlarge-desktop:        1024px;
@container-xl:                    @container-xlarge-desktop;

// --------------------------------------------------
// 1.4 - #Motion
// --------------------------------------------------

@timing-function:                 cubic-bezier(0.25, 0.8, 0.25, 1);
@duration:                        0.4s;

// --------------------------------------------------
// 1.5 - #Shadows
// --------------------------------------------------

@z-depth-1:                       0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);
@z-depth-2:                       0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
@z-depth-3:                       0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.3);
@z-depth-4:                       0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.3);
@z-depth-5:                       0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -5px rgba(0,0,0,0.3);

//--------------------------------------------------
// 1.1 - #Opacities
//--------------------------------------------------

@opacity-10:                      0.1;
@opacity-20:                      0.2;
@opacity-40:                      0.4;
@opacity-50:                      0.5;
@opacity-80:                      0.8;


// --------------------------------------------------
// 1.6 - #Misc.
// --------------------------------------------------

@border-radius:             4px;
@box-shadow:                0 0 5px 0 rgba(0,0,0,0.15);
@input-inset-shadow:        inset 0 0 5px rgba(0,0,0,0.15);
@tooltip-arrow-width:       @spacer / 10;
@tooltip-arrow-color:       white;
