/*

    Dropdowns
    --------------------------------------------------

    Table of Contents
    --------------------------------------------------
    :: 0.0 - #StyleGuide Definition
    :: 1.0 - #Modifiers
    :: 1.1 - #Containers
    :: 1.2 - #Dropdown - Copy
    :: 1.3 - #Dropdown - List
    :: 1.4 - #Dropdown - List Item
    :: 1.5 - #Dropdown - List Link

*/

// --------------------------------------------------
// 0.0 - #Styleguide Definitions
// --------------------------------------------------

/**
  * @stylesheet dropdowns.less Dropdowns
  * @parent Styles.components 4
  * @description
  * All defined dropdown styles and states belong here, including any "helper class" style options, like `default`, `primary` etc.*
  *
  * @demo demos/components/dropdowns/demo.html
  **/

// --------------------------------------------------
// 1.0 - #Modifiers
// --------------------------------------------------

.btn-dropdown-sml {

  .btn-dropdown-list-link {
    font-size: @spacer / 4;
  }

  .btn-dropdown-list-copy {
    margin-left: auto;
  }

}

// --------------------------------------------------
// 1.1 - #Containers
// --------------------------------------------------

.btn-dropdown {
  color: @link-color;
  padding-left: @spacer / 4;
  padding-top: @spacer / 2;
  padding-bottom: @spacer / 2;
  position: relative;
  min-width: @spacer * 3.1;
  text-align: right;
  cursor: pointer;

  &:hover {

    .btn-dropdown-copy {
      opacity: @opacity-40;
    }

    .btn-dropdown-list {
      .v-visible();

      @media @desktop-max {
        left: auto;
        bottom: auto;
      }
    }
  }
}

// --------------------------------------------------
// 1.2 - #Dropdown - Copy
// --------------------------------------------------

.btn-dropdown-copy {
  padding-right: 30px;
  position: relative;
  transition: all @duration @timing-function;

  &:before {
    top: @spacer / 6.666666667;
    right: @spacer / 10;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-top-color: @link-color;
    border-width: 6px;
    margin-left: -6px;
  }
}

// --------------------------------------------------
// 1.3 - #Dropdown - List
// --------------------------------------------------

.btn-dropdown-list {
  position: absolute;
  left: 0;
  right: 0;
  top: @spacer;
  background: white;
  box-shadow: @z-depth-1;
  transition: all @duration @timing-function;
  font-size: 0;
  .v-hidden();
}

// --------------------------------------------------
// 1.4 - #Dropdown - List Item
// --------------------------------------------------

.btn-dropdown-list-item {
  margin-bottom: 0;
}

// --------------------------------------------------
// 1.5 - #Dropdown - List Link
// --------------------------------------------------

.btn-dropdown-list-link {
  padding: @spacer / 6 @spacer / 4;
  line-height: 2.133333333;
  text-decoration: none;
  border-bottom: 1px;
  display: flex;
  align-items: center;
  transition: all @duration @timing-function;
  width: 100%;

  &:hover {
    background-color: @gray-lightest;
  }

  .@{icon-prefix}-icon {
    margin-right: @spacer / 4;
  }
}
