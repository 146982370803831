/*

    Page - Coverage Details
    --------------------------------------------------

    Table of Contents
    --------------------------------------------------
    :: 0.0 - #Modifiers
    :: 1.0 - #Containers
    :: 1.1 - #Sectoin - Apply Now

*/

//--------------------------------------------------
// 0.0 - #Modifiers
//--------------------------------------------------

//--------------------------------------------------
// 1.0 - #Containers
//--------------------------------------------------

.coverage-section-wrap {
  border-top: 1px solid @brand-primary;
  border-bottom: 1px solid @brand-primary;
  padding: @spacer / 4 0;
  margin-bottom: @spacer * 1.5;
  .clearfix();

  @media @desktop {
    display: flex;
  }
}

.coverage-intro-item {
  margin-bottom: @spacerMobile * 2;
  margin-top: @spacerMobile * 2;

  @media @desktop {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.coverage-icon-wrap {
  display: inline-block;
  margin-bottom: @spacer / 4;

  .@{icon-prefix}-icon {
      font-size: 100px;
  }
}

.coverage-section-title {
  border: none;
}

.coverage-section-content {
  display: flex;
  align-items: center;
  height: 100%;
}

//--------------------------------------------------
// 1.1 - #Section - Apply Now
//--------------------------------------------------

.page-section-wrap-cta {
  background-color: @brand-secondary;
  border-bottom: @spacer / 6 solid @brand-primary;
  height: @spacer * 4;
  position: relative;

  .container {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    @media @desktop {
      flex-direction: row;
    }

    &:before {
      content: " ";
      position: absolute;
      background: @brand-secondary;
      left: 50%;
      top: 0;
      width: 0;
      height: 0;
      border-left: 25px solid transparent;
      border-right: 25px solid transparent;
      border-top: 15px solid white;
      margin-left: -25px;

      @media @desktop {
        left: 0;
        margin-left: 2rem;
      }
    }

  }

  .btn {
    @media @desktop {
      margin-top: 0;
    }
  }

}

.page-section-wrap-cta-header {
  color: white;
  margin: 0;
  text-align: center;

  @media @desktop {
    width: 50%;
  }
}

.section-check-list {
  margin-bottom: 3rem;

  h4 {
    color: @text-color;
  }
}

.list-check-item {
  margin-bottom: @spacer / 4;
  padding-left: @spacer / 1.25;
  position: relative;

  .@{icon-prefix}-icon-check {
    position: absolute;
    left: 0;
    top: 50%;
    color: @brand-primary;
    font-size: 28px;
    transform: translate3d(0,-50%,0);
  }
}
