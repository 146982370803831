/*

    Components - Icons
    --------------------------------------------------

    Table of Contents
    --------------------------------------------------
    :: 0.0 - #Styleguide Definition
    :: 1.0 - #Icons
    :: 1.1 - #Icon Sprite
    :: 1.2 - #Icon Utility Classes

*/

// --------------------------------------------------
// 0.0 - #Styleguide Definition
// --------------------------------------------------

/**
  * @stylesheet icons.less SVG Icons
  * @parent Styles.critical 4
  * @description
  * Inlining SVG has so many benefits. We can use the technique for any image
  * asset on our site that can be rendered into SVG format (logos, icons, other
  * custom design elements).
  *
  * Whenever possible, this is our preferred method for images. This technique can
  * drastically cut down on the amount of requests we make to the server
  * (because all images are already embedded within the HTML of a page).
  *
  * Because of its vector format, SVG also maintains the highest resolution possible
  * which is super helpful to make sure everything looks good on mobile devices
  * and retina screens.
  *
  * In short, consider using SVG formats for just about everything.
  *
  * @demo demos/critical/svg-icons/demo.html
  **/


// --------------------------------------------------
// 1.0 - #Icons
// --------------------------------------------------

@icon-prefix: mp;

.@{icon-prefix}-icon {
  display: inline-block;
  vertical-align: top;
  width: 1em;
  height: 1em;
  fill: currentColor;
}

// --------------------------------------------------
// 1.1 - #Icon Sprite
// --------------------------------------------------

.@{icon-prefix}-icon-logo {

}

// --------------------------------------------------
// 1.2 - #Icon Utility Classes
// --------------------------------------------------