/*

    Nav - Mobile
    --------------------------------------------------

    Table of Contents
    --------------------------------------------------
    :: 1.0 - #Containers
    :: 1.1 - #Mobile Nav - Button
    :: 1.2 - #Mobile Nav - List

*/

//--------------------------------------------------
// 1.0 - Containers
//--------------------------------------------------

.nav-mobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: @zIndex-3--content-top;
  opacity: 0;
  visibility: hidden;
  background-color: @brand-primary;
  padding-top: @spacerMobile * 2;
  padding-left: @spacerMobile;
  padding-right: @spacerMobile;
  display: flex;
  align-items: center;
  transform: translate3d(0,0,0);
  color: white;

  @media @phone-landscape {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
  }

  @media @desktop {
    display: none;
  }
}

// --------------------------------------------------
// 1.1 - #Mobile Nav - Button
// --------------------------------------------------

.nav-button {
  width: 25px;
  height: 18px;
  display: block;
  cursor: pointer;
  position: relative;
  will-change: transform;
  margin-left: auto;

  // Increase the clickable / tappable area
  &:before {
    content: "";
    position: absolute;
    height: 67px;
    width: 70px;
    left: -21px;
    top: -21px;
  }

  @media @tablet {
    width: 30px;
    height: 23px;
  }

}

.nav-line {
  position: absolute;
  background-color: @brand-secondary;
  width: 100%;
  height: 2px;
  left: 0;
  transition: background-color @duration @timing-function;
  will-change: transform;
}

.nav-line-1 {
  top: 0;
}

.nav-line-2 {
  top: 8px;

  @media @tablet {
    top: 10px;
  }
}

.nav-line-3 {
  top: 16px;
  @media @tablet {
    top: 20px;
  }
}

// --------------------------------------------------
// 1.1 - #Mobile Nav - List
// --------------------------------------------------

.nav-list-mobile {
  transition: none;
  text-align: left;
  margin-right: auto;

  @media @phone-landscape {
    height: 100%;
  }

  .nav-list-item:last-child {
    .nav-list-link {
      padding-bottom: 0;
    }
  }
}

.nav-list-item-mobile {
  will-change: transform;
  &.active {
    .nav-list-link-mobile {
      font-weight: @bold;
      &:before {
        .v-visible();
      }
    }
  }
}

.nav-list-link-mobile {
  will-change: transform;
  transition: none;
  display: inline-block;
  padding: 0 0 @spacerMobile / 3;

  &:focus,
  &:active,
  &:hover {
    color: @gray-lighter;
  }

  &:before {
    content: " ";
    height: 2px;
    position: absolute;
    bottom: 7px;
    left: 0;
    right: 0;
    background-color: white;
    .v-hidden();
  }

  &.nav-list-item-btn {
    padding-bottom: 0;
    margin-bottom: @spacerMobile / 1.25;
    margin-left: 0;
    background-color: white;
    color: @brand-primary;
    width: @spacer * 1.75;
    text-align: center;

    @media @desktop {
      margin-left: @spacer / 4;
    }
  }

}
// We have to use a special class because on
// mobile the user's name isn't a link
// but it looks like one :-\
.mobile-nav-name {
  font-size: 1.25rem;
  text-decoration: none;
  padding-bottom: .625rem;
  display: block;
  transition: all .4s cubic-bezier(.25,.8,.25,1);
  color: inherit;
  text-transform: uppercase;
}
