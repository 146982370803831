/*

    Table - Equipment List
    --------------------------------------------------

    Table of Contents
    --------------------------------------------------
    :: 0.0 - #StyleGuide Definition
    :: 1.0 - #Modifiers
    :: 1.1 - #Containers
    :: 1.2 - #Table - Equipment List Add
    :: 1.3 - #Table - Equipment List
    :: 1.4 - #Table - Policy Review Equipment List

*/

// --------------------------------------------------
// 0.0 - #Styleguide Definitions
// --------------------------------------------------

// --------------------------------------------------
// 1.1 - #Modifiers
// --------------------------------------------------

// --------------------------------------------------
// 1.2 - #Containers
// --------------------------------------------------

.equipment-list-wrap {

  @media @desktop-xl {
    border: 1px solid @border-color;
  }

  .form-footer {
    margin: 0 0 @spacerMobile 0;
    border-top: 0;
    padding-top: 0;
    flex-direction: row;

    @media @desktop {
      margin-bottom: 0;
    }

    .btn {
      margin-bottom: 0;
      &:first-child {
        margin-right: @spacerMobile / 4;
      }
      &:last-child {
        margin-left: @spacerMobile / 4;
      }
    }
  }

}

// --------------------------------------------------
// 1.2 - #Table - Equipment List - Add
// --------------------------------------------------

.equipment-list-add {
  border-top: 1px solid @border-color;
  border-left: 1px solid @border-color;
  border-right: 1px solid @border-color;
  background-color: white;
  position: relative;

  @media @desktop {
    width: @spacer * 6;
  }

  @media @desktop-xl {
    border-top: 0;
    border-left: 0;
  }
}

.equipment-list-add-content {
  padding: @spacer / 2 @spacer / 2 0 @spacer / 2;
}

.equipment-list-add-title {
  margin-top: 0;
}

.items-added-wrap {
  background-color: @gray-darker;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: @spacer / 4 @spacer / 2;
}

.items-added-col-a {
  padding-right: @spacer / 2;
}

.items-added-col-b {
  text-align: right;
}

.items-added-copy {
  font-size: @spacer / 3.75;
  margin: 0;
  color: white;
}

// --------------------------------------------------
// 1.3 - #Table - Equipment List
// --------------------------------------------------

.table-empty-prompt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: @gray-lightest;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: @zIndex-2--content;
  text-align: center;
  padding: 0 @spacer * 3;

  @media @desktop-xl {
    padding: 0 @spacer * 5;
  }
}

.table-equipment-list {
  flex: 1;
  display: flex;
  flex-direction: column;

  @media @desktop {
    border-left: 0;
    border-bottom: 1px solid @border-color;
  }

  @media @desktop-xl {
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
  }

  .table-body {
    margin-top: 3.33333rem;
    padding-top: 0;
    flex: 1;
    max-height: 34.95rem;
  }
}

.equipment-table-body {
  @media @desktop-max {
    &:empty {
      &:before {
        content: 'No items are in your list yet.';
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        border-bottom: 1px solid @border-color;
      }
    }
  }
}

// --------------------------------------------------
// 1.3 - #Table - Equipment List - Row
// --------------------------------------------------

.table-row-wrap {
  overflow: hidden;
  user-select: none;
  transition: all @duration @timing-function;
  background-color: @brand-info-alpha;
  min-height: 0;

  .@{icon-prefix}-icon-collapse-down,
  .@{icon-prefix}-icon-collapse-up {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%,-50%,0);
    font-size: @spacer / 2;
  }

  .@{icon-prefix}-icon-collapse-up {
    .v-visible();
  }

  .@{icon-prefix}-icon-collapse-down {
    .v-hidden();
  }

  &.collapsed {
    background-color: transparent;
    .@{icon-prefix}-icon-collapse-up {
      .v-hidden();
    }
    .@{icon-prefix}-icon-collapse-down {
      .v-visible();
    }
  }
}

.table-row-collapse {
  transition: all @duration @timing-function;
  display: block;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid @border-color;
  background-color: @gray-lightest;
  font-size: 0;
  padding: 0 @spacerMobile / 2;

  @media @desktop {
    padding: 0;
  }
}

.collapse-inner-wrap {
  padding:  0 0 0 10%;
}

.mobile-edit-row {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid @border-color;
  padding: @spacer / 4 1.5rem @spacer / 4 10%;

  .table-cell-details-col {
    padding: 0;
  }

  @media @desktop {
    display: none;
  }
}

.table-cell-collapse {
  padding-top: @spacerMobile / 4;
  padding-bottom: @spacerMobile / 4;
  min-width: 30%;
  display: inline-block;
  vertical-align: middle;
  padding-right: @spacerMobile;

  @media @desktop {
    padding-left: @spacer / 2;
    padding-top: @spacer / 4;
    padding-bottom: @spacer / 4;
  }

}

.table-cell-collapse-copy {
  font-size: @spacer / 4;
  margin: 0;
}

.table-cell-collapse-header {
  text-transform: uppercase;
  font-weight: @bold;
  margin-bottom: -@spacer / 10;
}

// --------------------------------------------------
// 1.3 - #Table - Equipment List - Cells
// --------------------------------------------------

@tableCellWidthType:      10%;
@tableCellWidthModel:     50%;
@tableCellWidthValue:     20%;
@tableCellWidthDetails:   20%;

.table-cell-type {
  width: @tableCellWidthType;
  @media @tablet-max {
    width: 15%;
  }
  @media @phone-xs-max {
    width: 25%;
  }
}

.table-cell-model {
  width: @tableCellWidthModel;
  @media @tablet-max {
    width: 65%;
  }
  @media @phone-xs-max {
    width: 55%;
  }
}

.table-cell-value {
  width: @tableCellWidthValue;
  text-align: right;
  @media @phone-xs-max {
    display: none;
  }
}

.table-cell-details {
  width: @tableCellWidthDetails;
  text-align: right;
  @media @phone-xs-max {
    width: 15%;
  }
}

.table-cell-details-link {
  display: inline-block;
  font-size: 12px;
  text-align: right;
  float: right;
  vertical-align: middle;
}

.table-cell-details-col {
  font-size: 24px;
  vertical-align: middle;
  display: inline-block;
  padding: 0 0 0 @spacer / 4;
  color: @text-color-light;
  position: relative;

  .@{icon-prefix}-icon {
  }
}

.table-cell-delete {
  font-size: @spacer / 2.5;
  color: @text-color-light;
  display: block;
  height: 22px;

  &:hover {
    color: @brand-danger;
  }
}

.table-cell-link {
  font-size: @spacer / 5;
  display: block;
}

.table-head .table-cell-details {
  display: none;
  @media @desktop {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

// --------------------------------------------------
// 1.3 - #Table - Equipment List - Components
// --------------------------------------------------

.badge {
  width: @spacer / 2;
  height: @spacer / 2;
  background-color: white;
  border-color: @border-color;
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: @font-header;
  color: @brand-secondary;
  padding-bottom: 3px;
  padding-left: 1px;
}

.table-item-added {
  padding: @spacer / 4 @spacer / 2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%,-50%,0);
  background-color: @gray-lighter;
  border: 1px solid @border-color;
  font-size: 1rem;
  box-shadow: @z-depth-2;
  user-select: none;
  opacity: 0;
  animation: fade-out 1.5s ease-in-out forwards;

  .below-text {
    display: none;

    @media @desktop-max {
      display: inline;
    }
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.table-footer-mobile {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: @brand-secondary;
  padding: @spacer / 4;
  z-index: @zIndex-2--content;
  color: white;
  border-top: 4px solid @brand-primary;
  text-align: center;
  .btn {
    width: auto;
    margin: 0 auto;
    display: inline-block;
  }
}

.sort-arrow {
  font-size: 1.5rem;
  &.desc {
    transform: rotate(180deg);
  }
  @media @phone-xs-max {
    font-size: 1rem;
    vertical-align: middle;
  }
}

// --------------------------------------------------
// 1.4 - #Table - Policy Review Equipment List
// --------------------------------------------------

.policy-review-equipment-list {
  // This is needed on desktop because all the table columns are visable, but
  // clicking on it results in the drawer being shown. This makes no sense, but
  // we only need that at huge viewports and it's easier to do CSS for this than
  // in JS.
  .table-row-wrap {
    @media @desktop {
      pointer-events: none;
    }
  }

  .table-row {
    @media @desktop {
      cursor: default;
    }
  }
}
