
/**
 * Swiper 3.3.1
 * Most modern mobile touch slider and framework with hardware accelerated transitions
 *
 * http://www.idangero.us/swiper/
 *
 * Copyright 2016, Vladimir Kharlampidi
 * The iDangero.us
 * http://www.idangero.us/
 *
 * Licensed under MIT
 *
 * Released on: February 7, 2016
 */

/*
    Swiper JS
    --------------------------------------------------
    Table of Contents
    --------------------------------------------------
    :: 1.0 - Containers
    :: 1.1 - Mode View
*/

// --------------------------------------------------
// 1.0 - Containers
// --------------------------------------------------

.swiper-container {
  position: relative;
  z-index: 1;
  transform: translate3d(0,0,0);
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  font-size: 0;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  transform: translate3d(0px, 0, 0);
}

.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
}

// a11y
.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}

// IE10 Windows Phone 8 Fixes
.swiper-wp8-horizontal {
  touch-action: pan-y;
}

.swiper-wp8-vertical {
  touch-action: pan-x;
}
// Fade
.swiper-container-fade {
  .swiper-slide {
    pointer-events: none;
    transition-property: opacity;
    .swiper-slide {
      pointer-events: none;
    }
  }
  .swiper-slide-active {
    &, & .swiper-slide-active {
      pointer-events: auto;
    }
  }
}