.page-header {
  padding: @spacerMobile * 2 @spacerMobile;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;

  @media @desktop {
    display: block;
    padding: 0;
  }
}

//--------------------------------------------------
// 1.5 - #Imports
//--------------------------------------------------

@import "_header-lrg.less";
@import "_header-sml.less";
@import "_header-xs.less";