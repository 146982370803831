/*

    Accessibility
    --------------------------------------------------

    Table of Contents
    --------------------------------------------------
    :: 1.0 - Containers

*/

// --------------------------------------------------
// 1.0 - Containers
// --------------------------------------------------

#nav-skips {
  position: fixed;
  top: 50%;
  left: 0;
  z-index: 9999;
  list-style: none;
  width: 100%;
  margin: -30px 0 0;
  padding: 0;
  text-align: center;

  ul {
    position: fixed;
    top: 50%;
    left: 0;
    z-index: 9999;
    list-style: none;
    width: 100%;
    margin: -30px 0 0;
    padding: 0;
    text-align: center;
  }
  li {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }

  a {
    display: inline-block;
    position: fixed;
    left: -10001px;
    margin: 0 auto;
    padding: 20px 30px;
    border: 1px solid #fff;
    border-radius: 20px;
    background: @brand-primary;
    color: #fff;
    font-family: @font-family-base;
    font-size: 24px;
    text-align: center;
    text-decoration: none;
    outline: none;

    &:focus {
      position: relative;
      left: 0;
    }
  }
}

.skip-to {
  position: absolute;
  left: 370px;
  top: 25px;
  font-size: 1rem;
  color: transparent;
  &:focus {
    color: @brand-primary;
    .tab-focus();
  }
}

// Only display content to screen readers
//
// See: http://a11yproject.com/posts/how-to-hide-content/

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

// Use in conjunction with .sr-only to only display content when it's focused.
// Useful for "Skip to main content" links; see http://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
// Credit: HTML5 Boilerplate

.sr-only-focusable {
  &:active,
  &:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
  }
}
