/*

    Header - Small
    --------------------------------------------------

    Table of Contents
    --------------------------------------------------
    :: 1.0 - #Containers
    :: 1.1 - #Type
    :: 1.2 -

*/

//--------------------------------------------------
// 1.0 - #Containers
//--------------------------------------------------

.page-header-sml {
  width: 100%;
  background-color: @brand-primary;
  color: @text-color;
  text-align: center;

  h1 {
    color: white;
  }

  @media @desktop {
    height: @spacer * 5;
    background-color: transparent;
    text-align: center;
    margin-bottom: @spacer;

    h1 {
      color: @brand-secondary
    }
  }

}