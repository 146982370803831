/*

    Typography
    --------------------------------------------------

    Table of Contents
    --------------------------------------------------
    :: 0.0 - #Styleguide Definition
    :: 1.0 - #Baseline Grid
    :: 1.1 - #Headings
    :: 1.2 - #Body Copy
    :: 1.3 - #Lists
    :: 1.4 - #Links
    :: 1.5 - #HR line
    :: 1.6 - #Modifiers
    :: 1.7 - #Misc

*/

//--------------------------------------------------
// 0.0 - #Styleguide Definition
//--------------------------------------------------

/**
  * @stylesheet type.less Typography
  * @parent Styles.scaffolding 1
  * @description Let's get into the thick of it. All type styles and related elements
  * listed below.
**/

//--------------------------------------------------
// 1.0 - #Baseline Grid
//--------------------------------------------------

.baseline-grid {
  position: absolute;
  top: 1px;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: repeat;
  display: none;
  opacity: .5;
  z-index: 999;
  height: 4100px;
  background-image: url("/img/baseline.png");
}

// --------------------------------------------------
// 1.1 - #Headings
// --------------------------------------------------

/**
 * @styles headers Headers
 *
 * @description Header Headers
 * @demo demos/scaffolding/type/header/demo.html
 */


// Font Family: Bitter
// Light:    300;
// Regular:  400;
// Medium:   500;
// Bold:     700;
// Black:    800;

// Scale: http://www.modularscale.com/?10,20&px&1.5&web&text

.wf-active {
  h1, h2, h3, h4, h5, h6 {
    font-family: @font-header;
  }
}

h1, h2, h3, h4, h5, h6 {
  //font-family: @font-family-fallback-header;
  font-family: @font-header;
  font-weight: @headings-font-weight;
  line-height: @headings-line-height;
  margin-bottom: @spacer / 2;
}

h1 {
  font-size: @spacerMobile * 1.1;
  line-height: 1.5;
  color: @brand-secondary;

  @media @desktop {
    font-size: @spacer;
    line-height: 1.5;
  }
}

h2 {
  font-size: @spacerMobile;
  line-height: 1.5;
  color: @brand-secondary;

  @media @desktop {
    font-size: @spacer / 1.5;
    line-height: 3.375rem;
  }
}

h3 {
  font-size: @spacer / 2;
  line-height: 1.3;
  padding-bottom: @spacerMobile / 2;
  border-bottom: 1px solid @border-color;
  margin-bottom: @spacer / 2;
  color: @brand-secondary;

  @media @desktop {
    font-size: @spacer / 2;
    padding-bottom: @spacer / 4;
    line-height: 1.25;
  }
}

h4 {
  font-size: @spacer / 2.5;
  line-height: 1.25;

  @media @desktop {
    font-size: @spacer / 2.5;
    line-height: 1.5;
  }
}

h5 {
  font-size: @spacer / 3;
  line-height: 1.5;
  color: @text-color;
  margin-bottom: @spacerMobile / 2;
  @media @desktop {
    font-size: @spacer / 2.5;
    line-height: 1.5;
    margin-bottom: @spacer / 4;
  }
}

h6 {
  font-size: 0.875rem;
  line-height: 1rem;

  @media @desktop {
    font-size: 0.8333333334rem;
    line-height: 1.3333333334rem;
  }
}

// --------------------------------------------------
// 1.2 - #Body Copy
// --------------------------------------------------

/**
 * @styles body-copy Body Copy
 *
 * @description Body copy base
 * @demo demos/scaffolding/type/body_copy/demo.html
 */

p {
  .font-size(@remSm, @remMd, @remLg);
  line-height: @line-height-base;
  margin-top: @spacerMobile / 2;
  margin-bottom: @spacerMobile / 2;

  @media @desktop {
    margin-top: @spacer / 2;
    margin-bottom: @spacer / 2;
  }
}

.p-xl {
  font-size: @spacer / 2;
}

.p-lrg {
  font-size: @spacer / 2.5;
}

.p-md {
  font-size: @spacer / 3.333333333;
}

.p-sml {
  .font-size(12,14,16);
  margin: @spacer / 6 0;
  color: @text-color-light;
}

// --------------------------------------------------
// 1.3 - #Lists
// --------------------------------------------------

ul, ol {
  padding: 0;

  ul, ol {
    margin-top: @spacerMobile / 1.5;
    padding-left: 1rem;

    @media @desktop {
      margin-top: @spacer / 4;
    }
  }

  li {

    margin-bottom: @spacerMobile / 1.5;

    @media @desktop {
      margin-bottom: @spacer / 4;
    }
  }
}

.list-unstyled {
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 0;
}

// Inline turns list items into inline-block
.list-inline {
  .list-unstyled();

  > li {
    display: inline-block;
    vertical-align: middle;
  }
}


/**
 * @styles ordered-list OL
 *
 * @description OL example
 * @demo demos/scaffolding/lists/ol_list/demo.html
 */

.ol-list {
  padding: 0;

  li {
    margin-bottom: 1.52222223rem;
  }
}


/**
 * @styles unordered-lists UL
 *
 * @description UL example
 * @demo demos/scaffolding/lists/ul_list/demo.html
 */

// --------------------------------------------------
// 1.4 - #Links
// --------------------------------------------------

/**
 * @styles links Links
 *
 * @description Type links
 * @demo demos/scaffolding/type/links/demo.html
 */

a {
  .font-size(@remSm, @remMd, @remLg);
  line-height: @line-height-base;
  color: @brand-primary;
  text-decoration: underline;
  font-weight: @regular;
  transition: color @duration @timing-function;

  &:hover {
    color: @brand-secondary;
  }

  &:focus {
    .tab-focus();
  }

}

.link-warning {
  color: @brand-danger;

  &:hover {
    color: darken(@brand-danger, 15%);
  }
}

.link-sml {
  font-size: @spacer / 4.285714286;
}


// --------------------------------------------------
// 1.5 - #HR Line
// --------------------------------------------------

/**
 * @styles hr-line HR line
 *
 * @description HR Line
 * @demo demos/scaffolding/hr_line/demo.html
 */

hr {
  width: 100%;
  border: none;
  border-bottom: 1px solid @gray-light;
}

// --------------------------------------------------
// 1.6 - #Modifiers
// --------------------------------------------------

/**
 * @styles modifiers Modifiers
 *
 * @description Type modifiers
 * @demo demos/scaffolding/modifiers/demo.html
 */

.bold {
  font-weight: @bold;
}

.text-error {
  color: @brand-danger;
  font-weight: @bold;

  &:hover {
    color: darken(@brand-danger, 15%);
  }
}

.text-color-brand {
  color: @brand-primary;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-left           { text-align: left; }
.text-right          { text-align: right; }
.text-center         { text-align: center; }
.text-justify        { text-align: justify; }
.text-nowrap         { white-space: nowrap; }
.text-white          { color: white; }


// --------------------------------------------------
// 1.7 - #Misc
// --------------------------------------------------

address {
  color: inherit;
  font-style: normal;
  font-size: 1rem;
}
