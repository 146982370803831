/*

    Page - Claims
    --------------------------------------------------

    Table of Contents
    --------------------------------------------------
    :: 0.0 - #Containers
    :: 1.0 - #Section - Claims Contact

*/


//--------------------------------------------------
// 1.0 - #Containers
//--------------------------------------------------

//--------------------------------------------------
// 1.0 - #Section - Claims Contact
//--------------------------------------------------

.claims-contact-item-wrap {
  font-size: 0;
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
  margin-bottom: @spacerMobile;

  @media @desktop {
    margin-bottom: @spacer / 2;
  }

}

.contact-item-icon {
  font-size: @spacer / 2.75;
  color: @brand-secondary;
  margin-right: @spacer / 4;
  padding-top: @spacer / 10;
}

.claims-contact-item-link {
  text-transform: none;
}

//--------------------------------------------------
// 1.0 - #Section - Losses
//--------------------------------------------------

.claims-section-wrap {
  padding: @spacerMobile @spacerMobile @spacerMobile / 2 @spacerMobile;
  background-color: white;
  margin: @spacerMobile / 2 0;

  @media @desktop {
    padding: @spacer @spacer @spacer / 2 @spacer;
    margin: @spacer / 2 0;
  }

}

.claims-section-list {
  padding-left: @spacer / 4;
  margin: 0;
}

.claims-section-btn-wrap {

  .btn {
    width: 100%;
    margin: 0;
  }

  @media @desktop {
    display: flex;
    flex-direction: row;
    align-items: center;
    .btn {
      margin: @spacer / 4 0;
      width: auto;
    }
  }
}

.claims-section-divider {
  display: block;
  padding: @spacerMobile / 2;
  text-align: center;
}
