/*

    Scaffolding
    --------------------------------------------------

    Table of Contents
    --------------------------------------------------
    :: 1.0 - #Box Sizing
    :: 1.0 - #Modifiers
    :: 1.1 - #Containers
    :: 1.2 - #Mobile

*/

//--------------------------------------------------
// 1.0 - #Default Settings
//--------------------------------------------------

* {
  box-sizing: border-box;
}

*:before,
*:after {
  box-sizing: border-box;
}

::selection {
  background: lighten(@brand-primary, 40%);
}
::-moz-selection {
  background: lighten(@brand-primary, 40%);
}


//--------------------------------------------------
// 1.1 - #Modifiers
//--------------------------------------------------

//--------------------------------------------------
// 1.1 - #Containers
//--------------------------------------------------

html,
body {
  min-height: 100%;
}

html {
  font-size: @font-size-base;
  //font-family: @font-family-fallback;
  font-family: @font-family-base;
  line-height: @line-height-base;

}

.wf-active {
  font-family: @font-family-base;
  .loader-wrap {
    .v-hidden();
  }
}


body {
  color: @text-color;
  background-color: @body-bg;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(255,255,255,0.2);
  animation: fade 1s @timing-function;
  display: flex;
  flex-direction: column;
  padding-top: @spacerMobile * 2;

  &.no-scroll {
    overflow: hidden;
  }

  @media @desktop {
    padding-top: @spacer * 2;
  }

}

@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}