/*

    Table - Summary
    --------------------------------------------------

    Table of Contents
    --------------------------------------------------
    :: 1.0 - #Modifiers
    :: 1.1 - #Containers
    :: 1.2 - #Table Summary - Columns

*/

//--------------------------------------------------
// 1.0 - #Modifiers
//--------------------------------------------------

.table-history-container-wrap {
  margin-top: 0;
}

//--------------------------------------------------
// 1.1 - #Containers
//--------------------------------------------------


.table-cell-row-wrap {
  display: flex;
  flex-direction: row;
}

.table-cell-row-cell {
  flex: 1;
  padding: @spacer / 4 0;
}

.table-cell-label,
.table-cell-copy {
  font-size: @spacer / 5;
  margin: 0;
}

.table-cell-label {
  text-transform: uppercase;
  font-weight: @bold;
}

.table-cell-copy-sml {
  font-size: @spacer / 4;
  margin: @spacer / 10 0 @spacer / 8 0;
  line-height: 1.2;
}

.table-cell-copy-link {
  display: block;
}

.table-history {
  @media @tablet-max {
    .table-cell {
      white-space: normal;
    }
    .table-cell-row-wrap {
      flex-wrap: wrap;
    }
  }
  @media @phone-xs-max {
    .table-cell-row-cell {
      flex: none;
    }
  }
}


//--------------------------------------------------
// 1.2 - #Table Summary - Columns
//--------------------------------------------------

@tableCellWidthHistoryType:         15%;
@tableCellWidthHistoryDesc:         85%;

.table-cell-history-date {
  width: 30%;
  @media @desktop {
    width: @tableCellWidthHistoryType;
  }
}
.table-cell-history-desc {
  width: 70%;
  @media @desktop {
    width: @tableCellWidthHistoryDesc;
  }
}
