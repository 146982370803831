/*

    Component - Progress Bar
    --------------------------------------------------

    Table of Contents
    --------------------------------------------------
    :: 1.0 - #Containers
    :: 1.1 - #Type
    :: 1.2 -

*/

//--------------------------------------------------
// 1.0 - #Containers
//--------------------------------------------------

.progress {
  overflow: hidden;
  height: @spacer / 8;
  margin-bottom: @spacer / 4;
  background-color: @gray-lighter;
}

.progress-bar {
  float: left;
  width: 50%;
  height: 100%;
  color: @text-color;
  text-align: center;
  background-color: @brand-primary;
  transition: all @duration @timing-function;
}

.progress-bar-success {

}

.progress-bar-info {

}

.progress-bar-warning {

}

.progress-bar-danger {

}

.progress-labels-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.progress-labels-copy {
  margin: 0;
}
