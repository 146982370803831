/*

    Page - Master
    --------------------------------------------------

    Table of Contents
    --------------------------------------------------
    :: 1.0 - #Modifiers
    :: 1.1 - #Containers
    :: 1.2 - #Components
    :: 1.2 - #Section - title

*/

//--------------------------------------------------
// 1.0 - #Modifiers
//--------------------------------------------------

.is-full {
  height: 100vh;
  padding-top: 0;

  .page-wrap {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
  }

}

//--------------------------------------------------
// 1.1 - #Containers
//--------------------------------------------------

.page-wrap {
  min-height: 100%;
  position: relative;
  padding: @spacerMobile @spacerMobile / 2 @spacerMobile;

  @media @desktop {
    padding: @spacer 0;
  }
}

//--------------------------------------------------
// 1.3 - #Components
//--------------------------------------------------

.page-section-wrap {
  padding: 0 @spacerMobile / 2 @spacerMobile;

  @media @desktop {
    padding: @spacer / 2 @spacer / 2;
  }
}

.page-section-bottom {
  margin-bottom: @spacer / 2;
}

.page-section-wrap-gray {
  background-color: @gray-lightest;
}

.page-copy-list {
  padding-left: @spacer / 2;
  ul {
    margin-top: @spacerMobile / 2;
    @media @desktop {
      margin-top: @spacer / 4;
    }
  }
  li {
    margin-bottom: @spacerMobile / 2;
    .font-size(@remSm, @remMd, @remLg);
    line-height: @line-height-base;

    p {
      margin: 0;
    }

    @media @desktop {
      margin-bottom: @spacer / 6;
    }
  }
}

.page-img-wrap {
  padding: @spacerMobile 0 0 0;
  @media @desktop {
    padding: @spacer 0;
  }
}

.page-img {
  width: 100%;
}

.page-flex {
  @media @desktop {
    display: flex;
    align-items: center;
  }
}

.page-title-dark {
  color: @text-color;
  border-bottom: 0;
  padding-bottom: 0;
}

.page-title-blue {
  color: @brand-secondary;
}

//--------------------------------------------------
// 1.1 - #Section - Title
//--------------------------------------------------

.section-title-wrap-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  .section-title {
    margin-right: @spacerMobile / 2;
  }
}

.section-title {
  width: 100%;
  position: relative;
}

.section-title-link,
.section-title-btn {
  float: right;
}

.section-title-btn {
  margin: 0;
  width: auto;
}

.section-title-link {
  padding-top: @spacer / 4.615384615;
}

.section-title-copy {
  font-size: @spacer / 4;
  color: @text-color-light;
  font-family: @font-family-base;
  font-weight: @regular;
  margin-top: -@spacer / 5;
  //display: block;
  //width: 100%;

  @media @phone {
    //margin-top: @spacer / 6;
    display: inline-block;
    width: auto;
  }

}

.section-title-tooltip {
  position: absolute;
  right: 0;
  top: 10px;
  color: @brand-primary;
  display: block;
  height: @spacer / 2;
  width: @spacer / 2;
  .@{icon-prefix}-icon {
    font-size: @spacer / 2.5;
  }
}
