/*

    Page - Application - Welcome
    --------------------------------------------------

    Table of Contents
    --------------------------------------------------
    :: 1.0 - #Page - Containers

*/

//--------------------------------------------------
// 1.0 - #Containers
//--------------------------------------------------

.classical-instruments {
  margin-top: 2.75rem;

  .form-control-label,
  .form-group-has-content .form-control-label {
    top: -2.25rem;
    font-size: .875rem;
  }

  @media @desktop-lg {
    margin-top: 2rem;
    .form-control-label,
    .form-group-has-content .form-control-label {
      top: -1.111111rem;
      font-size: .875rem;
    }
  }
}
