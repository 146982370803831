/*

    Table - Summary
    --------------------------------------------------

    Table of Contents
    --------------------------------------------------
    :: 1.0 - #Modifiers
    :: 1.1 - #Containers
    :: 1.2 - #Table Summary - Columns

*/

//--------------------------------------------------
// 1.0 - #Modifiers
//--------------------------------------------------

//--------------------------------------------------
// 1.1 - #Containers
//--------------------------------------------------

.table-container-coverage-areas {
  max-width: 100%;
  min-width: 100%;
}

//--------------------------------------------------
// 1.2 - #Table Summary - Columns
//--------------------------------------------------

// @TODO This might need to be variable depending on the viewport. On skinny
// screens, the last 2 table headers overlap.
@tableCellCoverageAreasWidth: 33%;

.table-coverage-areas {
  .table-cell-coverage-areas-state,
  .table-cell-coverage-areas-number,
  .table-cell-coverage-areas-holder {
    width: @tableCellCoverageAreasWidth;
  }

  .table-row {
    cursor: default;

    &:hover {
      background-color: white;
    }
  }
}
