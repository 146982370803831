/*

    Table Master
    --------------------------------------------------

    Table of Contents
    --------------------------------------------------
    :: 0.0 - #StyleGuide Definition
    :: 1.0 - #Containers
    :: 1.1 - #Table - Header
    :: 1.2 - #Table - Body
    :: 1.3 - #Table - Row
    :: 1.4 - #Table - Data
    :: 1.5 - #Imports

*/

// --------------------------------------------------
// 0.0 - #Styleguide Definitions
// --------------------------------------------------


/**
  * @parent Styles.components 4
  * @stylesheet /scaffolding/tables.less Tables
  * @description
  * All form related definitions here
  *
**/


// --------------------------------------------------
// 1.0 - #Containers
// --------------------------------------------------

.table-container-wrap {
  width: 100%;
  padding: 0 @spacerMobile / 2;

  @media @desktop {
    display: flex;
    flex-direction: row;
    margin: @spacer auto 0 auto;
    max-height: @spacer * 11.583333333;
  }

  @media @desktop-xl {
    background-color: @gray-lighter;
    min-width: @spacer * 24;
    max-width: @spacer * 24;
    padding: 0;
  }
}

.table-container-wrap-full {
  max-height: none;
}

.table {
  font-size: @spacer / 4;
  background-color: white;
  position: relative;
  flex: 1;
  border-top: 1px solid @border-color;
  border-left: 1px solid @border-color;
  border-right: 1px solid @border-color;
  border-bottom: 1px solid @border-color;
  margin-top: @spacerMobile;
  width: 100%;

  @media @desktop {
    margin-top: 0;
  }

}

.table-static {
  .table-row {
    &:hover {
      background-color: white;
      cursor: default;
    }
  }
}

// --------------------------------------------------
// 1.1 - #Table - Header
// --------------------------------------------------

.table-head {
  background-color: @gray-lightest;
  border-bottom: 1px solid @border-color;
  padding: @spacer / 4;
  min-height: @spacer;
  text-transform: uppercase;
  font-weight: @bold;
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: @zIndex-2--content;
  white-space: nowrap;
  align-items: center;

  @media @phone-xs-max {
    padding: 0.25rem;
    flex-wrap: wrap;
  }
}

.table-header {
  padding: 0 @spacer / 4;
  @media @phone-xs-max {
    padding: 0.25rem;
  }

  @media @desktop {
    flex: 1 1 150px;
  }
}

// --------------------------------------------------
// 1.2 - #Table - Body
// --------------------------------------------------

.table-body {
  padding-top: @spacer;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: @gray-lightest;
}

// --------------------------------------------------
// 1.3 - #Table - Row
// --------------------------------------------------

.table-row {
  padding: @spacer / 4;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid @border-color;
  cursor: pointer;
  transition: all @duration @timing-function;
  min-height: 3.33333333rem;

  &:hover {
    background-color: @brand-info-alpha;
  }

  &:last-child {
    border-bottom: 0;
  }

  @media @phone-xs-max {
    padding: 0.25rem;
  }
}

// --------------------------------------------------
// 1.4 - #Table - Cell
// --------------------------------------------------

.table-cell {
  padding: 0 @spacer / 4;
  height: 100%;
  @media @phone-xs-max {
    padding: 0 0.25rem;
    .text-overflow();
  }

  @media @desktop {
    flex: 1 1 150px;
  }
}

// --------------------------------------------------
// 1.5 - #Imports
// --------------------------------------------------

@import "_table-accounts.less";
@import "_table-equipment-list.less";
@import "_table-faq.less";
@import "_table-policy-history.less";
@import "_table-summary.less";
@import "_table-coverage-areas.less";
