/*

    Buttons
    --------------------------------------------------

    Table of Contents
    --------------------------------------------------
    :: 0.0 - #Styleguid Definition
    :: 1.0 - #Base
    :: 1.1 - #Button - Secondary
    :: 1.2 - #Button - With Icon
    :: 1.3 - #Button - Inline

*/

//--------------------------------------------------
// 0.0 - #Styleguide Definition
//--------------------------------------------------

/**
  * @stylesheet buttons.less Buttons
  * @parent Styles.components 4
  * @description
  * All defined button styles and states belong here, including any "helper class" button style options, like `default`, `primary` etc.*
  * Core site scaffolding
  **/


/**
 * @styles default-button Default Button
 *
 * @description primary button
 * @demo demos/components/buttons/demo.html
*/


//--------------------------------------------------
// 1.0 - #Base
//--------------------------------------------------

.btn {
  border: 0;
  background: none;
  background-color: @brand-primary;
  font-size: @spacer / 4.285714286;
  line-height: 2.571428571;
  letter-spacing: .01em;
  font-family: @font-family-base;
  font-weight: @medium;
  text-transform: uppercase;
  text-align: center;
  color: white;
  display: inline-block;
  transition: background-color @duration @timing-function, color @duration @timing-function;
  margin-top: @spacer / 3;
  border-radius: 2px;
  text-decoration: none;
  box-shadow: @z-depth-1;
  min-width: @spacer * 2;
  min-height: @spacer / 1.666666667;
  padding: 0 @spacer / 3.75;
  position: relative;
  overflow: hidden;
  width: 100%;

  &:hover {
    background-color: @link-hover-color;
    color: white;
    cursor: pointer;
  }

  &:active {
    outline: none;
    background-color: @link-hover-color;
    box-shadow: 0 0 0;
  }

  &:disabled {
    opacity: @opacity-40;
    background-color: @gray;
    &:hover {
      background-color: @gray;
      cursor: not-allowed;
    }
  }

  @media @desktop {
    width: auto;
  }

}

.btn-copy {
  display: block;
  padding: 0 @spacer / 3.75;
}

.btn-ink {
	display: block;
  position: absolute;
	background: rgba(255,255,255,0.45);
	border-radius: 100%;
	transform: scale(0);
  &.animate {
    animation: ripple 0.65s linear;
  }
}

@keyframes ripple {
	100% {
    opacity: 0;
    transform: scale(2.5);
  }
}

//--------------------------------------------------
// 1.1 - #Button - Secondary
//--------------------------------------------------

/**
 * @styles secondary-button Secondary Button
 *
 * @description primary button
 * @demo demos/components/buttons/secondary/demo.html
*/

.btn-secondary {
  background-color: @gray-light;
  color: @gray-darker;

  &:hover {
    color: white;
    background-color: darken(@gray-light, 15%);
  }
  &:disabled {
    color: @gray-darker;
  }
}

.btn-danger {
  background-color: @brand-danger;

  &:hover {
    background-color: darken(@brand-danger, 15%);
  }
}

//--------------------------------------------------
// 1.2 - #Button - Lg
//--------------------------------------------------

.btn-lg {
  line-height: 56px;
  font-size: 16px;
  .btn-copy {
    padding: 0 24px;
  }
}

.btn-white {
  background-color: white;
  .btn-copy {
    color: @link-color;
  }

  &:hover {
    .btn-copy {
      color: white;
    }
  }
}

//--------------------------------------------------
// 1.3 - #Button - Small
//--------------------------------------------------

//--------------------------------------------------
// 1.4 - #Button - With Icon
//--------------------------------------------------

/**
 * @styles button-with-icon Button With Icon
 *
 * @description primary button
 * @demo demos/components/buttons/btn-with-icon/demo.html
*/

.btn-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 @spacer / 3.75;
  width: 100%;
  text-align: center;
  justify-content: center;
  margin-bottom: @spacer / 2;

  .@{icon-prefix}-icon {
    font-size: @spacer / 3;
  }

  @media @desktop-xl {
    width: 50%;
  }
}

.btn-upload {
  &:hover {
    background-color: @brand-success;
  }

  .btn-copy {
    max-width: 90%;
    .text-overflow();
  }
}


//--------------------------------------------------
// 1.5 - #Button - Inline
//--------------------------------------------------

.btn-inline {
  width: auto;
}
