/*

    Header - Large
    --------------------------------------------------

    Table of Contents
    --------------------------------------------------
    :: 1.0 - #Containers
    :: 1.1 - #Type
    :: 1.2 -

*/

//--------------------------------------------------
// 1.0 - #Containers
//--------------------------------------------------


.page-header-lrg {
  background-image: url('/static/img/bg-header-home.jpg');
  background-color: @brand-secondary;
  background-position: center;
  background-size: cover;
  padding-top: @spacer * 2;
  text-align: center;
  margin: 0 auto;
  width: 100%;
  height: @spacer * 6;

  @media @desktop {
    height: @spacer * 10;
  }
}

.page-header-title-lrg {
  color: white;
  max-width: @spacer * 14;
  margin: 0 auto;
  text-shadow: 2.5px 4.33px 4.7px rgba(0, 0, 0, 0.25);
  width: 100%;
}
