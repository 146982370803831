/*

    Components - Tabs
    --------------------------------------------------

    Table of Contents
    --------------------------------------------------
    :: 1.0 - #Modifiers
    :: 1.1 - #Tab - List
    :: 1.2 - #Tab - List Item
    :: 1.3 - #Tab - List Item Link
    :: 1.4 - #Tab - List Item Copy

*/


//--------------------------------------------------
// 1.0 - #Modifiers
//--------------------------------------------------

.fade {
  opacity: 0;
  transition: opacity @duration @timing-function;

  &.in {
    opacity: 1;
  }
}


.tab-content {
  > .tab-pane {
    display: none;
  }
  > .active {
    display: block;
  }
}

//--------------------------------------------------
// 1.1 - #Tab - List
//--------------------------------------------------

.tabs-list {
  position: absolute;
  bottom: 15px;

  @media @tablet {
    bottom: 18px;
  }
}

//--------------------------------------------------
// 1.2 - #Tab - List Item
//--------------------------------------------------

.tab-list-item {
  margin-bottom: 0;
}

//--------------------------------------------------
// 1.3 - #Tab - List Item Link
//--------------------------------------------------

.tab-list-item-link {
  text-transform: uppercase;
  font-weight: @bold;
  color: white;
  text-decoration: none;
  opacity: @opacity-40;
  position: relative;
  margin-right: @spacerMobile / 2;
  .active & {
    opacity: 1;
    pointer-events: none;
    .tab-list-item-copy:before {
      .v-visible();
    }
  }

  @media @desktop {
    padding-right: @spacer;
  }

  &:hover {
    color: @link-color;
  }
}

//--------------------------------------------------
// 1.4 - #Tab - List Item Copy
//--------------------------------------------------

.tab-list-item-copy {
  &:before {
    content: " ";
    position: absolute;
    bottom: -@spacer / 2.727272727;
    height: 6px;
    background-color: @brand-primary;
    width: 100%;
    .v-hidden();
  }
}
