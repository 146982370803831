@media print {
  .page-header {
    height: 5rem;
  }

  .page-wrap {
    padding: 0;
  }

  .footer {
    display: none;
  }

  // policy receipt
  .card-policy {
    .card-footer {
      display: none;
    }
  }
}
