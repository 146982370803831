/*

    Table - Accounts
    --------------------------------------------------

    Table of Contents
    --------------------------------------------------
    :: 1.0 - #Modifiers
    :: 1.1 - #Containers
    :: 1.2 - #Table Accounts - Columns
    :: 1.3 - ##Table Accounts - Components

*/

//--------------------------------------------------
// 1.0 - #Modifies
//--------------------------------------------------

//--------------------------------------------------
// 1.1 - #Containers
//--------------------------------------------------

.table-accounts {
  border: 1px solid @border-color;
  .table-head,
  .table-row {
    justify-content: space-between;
  }

  .table-row {
    min-height: @spacer;
    height: auto;
  }
}

//--------------------------------------------------
// 1.2 - #Table Accounts - Columns
//--------------------------------------------------

@tableCellWidthAccountsNick:         40%;
@tableCellWidthAccountsName:         60%;
@tableCellWidthAccountsManage:       @spacer;
@tableCellWidthAccountsDelete:       @spacer;

.table-cell-accounts-nick {
  width: @tableCellWidthAccountsNick;
}

.table-cell-accounts-name {
  width: @tableCellWidthAccountsName;
}

.table-cell-accounts-manage {
  width: @tableCellWidthAccountsManage;
}

.table-cell-accounts-delete {
  width: @tableCellWidthAccountsDelete;
}

//--------------------------------------------------
// 1.3 - #Table Accounts - Components
//--------------------------------------------------

.form-group-table {
  margin: 0;
  padding: 0;
}

.form-control-table {
  background-color: transparent;
  margin: 0;
  padding: 0;
  font-size: @spacer / 4;
}