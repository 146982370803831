/*

    Page - Application - Master
    --------------------------------------------------

    Table of Contents
    --------------------------------------------------
    :: 1.0 - #Modifiers
    :: 1.1 - #Containers

*/


//--------------------------------------------------
// 1.0 - #Modifiers
//--------------------------------------------------

//--------------------------------------------------
// 1.1 - #Containers
//--------------------------------------------------

.form-full-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  //background-color: @gray-lighter;
  flex-direction: column;
  min-height: 50vh;
  @media @desktop {
    padding: @spacer;
  }
}

.thankyou-wrap {
  min-height: 50vh;
  @media @desktop {
    padding: @spacer;
  }
}

.form-wrap {
  padding-top: @spacer;
  margin-bottom: @spacer;
}

.form-section {
  margin: @spacerMobile 0 @spacerMobile / 2 0;
  @media @desktop {
    margin: @spacer / 2 0 @spacer 0;
  }
}

//--------------------------------------------------
// 1.1 - #Page - Footer
//--------------------------------------------------

.form-footer {
  border-top: 1px solid @border-color;
  position: relative;
  padding: @spacerMobile 0 0 0;
  margin-top: @spacerMobile;
  display: flex;
  flex-direction: column-reverse;

  .btn {
    margin-top: 0;
    margin-bottom: @spacer / 2;
  }

  .btn-danger {
    display: block;
    margin-bottom: 0;
  }

  .btn-secondary {
    margin-bottom: @spacer / 2;
  }

  @media @desktop {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    //margin: @spacer 0 @spacer / 2 0;
    //padding: @spacer / 2 0;
  }

}

.form-footer-center {
  justify-content: center;
}

.form-footer-cancel-link {
  color: @brand-danger;
  font-size: @spacer / 4;
  margin: 0 @spacer / 4 @spacer / 4 0;
  display: block;
}

//--------------------------------------------------
// 1.1 - #Page - Imports
//--------------------------------------------------

@import "pages/apply/_apply-step-0";
@import "pages/apply/_apply-step-3";
@import "pages/apply/_apply-step-4";
