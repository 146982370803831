/*
    Forms
    --------------------------------------------------

    Table of Contents
    --------------------------------------------------
    :: 1.0 - #Styleguide Definitions
    :: 1.1 - #Form Group
    :: 1.2 - #From Group - Animated
    :: 1.3 - #Form Group - Inline
    :: 1.4 - #Form Control
    :: 1.5 - #Form Control - Label
    :: 1.6 - #Form Control - Checkbox
    :: 1.7 - #Form Control - Radio
    :: 1.8 - #Form Control - Textarea
    :: 1.9 - #Form Control - Select
    :: 1.10 - #Form Control - Application Type
    :: 1.11 - #Form Control - Addon

*/

// --------------------------------------------------
// 1.0 - #Styleguide Definitions
// --------------------------------------------------

/**
  * @parent Styles.components 4
  * @stylesheet /scaffolding/forms.less Forms
  * @description
  * All form related definitions here
  *
**/


// --------------------------------------------------
// 1.1 - #Form Group
// --------------------------------------------------

.form-group {
  position: relative;
  padding-top: @spacer / 10;
  margin-bottom: @spacer / 2;
}

// --------------------------------------------------
// 1.2 - #Form Group - animated
// --------------------------------------------------

/**
 * @styles animated-form-control Animated form-control
 *
 * @description The primary text field style
 *
 * @demo demos/scaffolding/form_components/animated_input/demo.html
 */

.form-group-animated {

  .form-control-label {
    font-size: @spacer / 3.333333333;
    top: @spacer / 2.5;
  }

  .form-control {
    &:focus {
      ~ .form-control-label {
        top: -12px;
        font-size: 15px;
        color: @brand-primary;
      }
    }
  }

}

// Checks when there is content in the form-control
.form-group-animated.form-group-has-content {
  .form-control-label {
    top: -12px;
    font-size: 15px;
    color: @brand-primary;
  }
  .form-control,
  .form-control-select {
    color: @text-color;
  }
}

// --------------------------------------------------
// 1.3 - Error States
// --------------------------------------------------

.form-group-has-error {

  .form-control-label,
  .form-control-select,
  .form-control-select,
  .form-control-radio-label,
  .form-control-label-checkbox {
    color: @brand-danger;
  }

  &.form-group-select:before {
    border-top-color: @brand-danger;
  }

  .form-control,
  .form-control-select {
    border-bottom-color: @brand-danger;
    border-bottom-width: 1px;
  }

}

.error-copy {
  font-size: @spacer / 5;
  color: @brand-danger;
}

// --------------------------------------------------
// 1.3 - Success States
// --------------------------------------------------

.success-message-wrap {
  font-weight: bold;
  color: @brand-primary;
}

// --------------------------------------------------
// 1.3 - #Form Group - Inline
// --------------------------------------------------

/**
 * @styles radio_inline Radio Inline
 *
 * @description inline radio option
 * @demo demos/scaffolding/form_components/radio_inline/demo.html
 */

.form-group-inline {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  .checkbox-radio-item-wrap {
    margin-right: 2.5rem;
  }

}

// --------------------------------------------------
// 1.4 - #Form Control
// --------------------------------------------------

/**
 * @styles non-animated-form-control Non Animated form-control
 *
 * @description The primary text field style
 *
 * @demo demos/scaffolding/form_components/non-animated-input/demo.html
 */

.form-control {
  width: 100%;
  display: block;
  position: relative;
  font-family: @font-family-base;
  font-weight: @regular;
  font-size: 1rem;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid @border-color;
  margin-bottom: 1rem;
  padding: @spacer / 6 0;
  border-radius: 0;
  .text-overflow();

  &[disabled],
  &.disabled {
    opacity: @opacity-40;
    &:hover {
      ~ .form-control-label {
        color: @text-color-light;
      }
    }
  }

  &:hover,
  &:focus {
    outline: none;
    + .form-control-label {
      color: @brand-primary;
    }
  }

}

.form-group-disabled {

  .form-control {
    border-bottom: 0;
    pointer-events: none;
  }

  .form-control ~ .form-control-label {
    color: @text-color-light;
  }

}

/* Animated bottom line on input focus */
.input-bar {
  display: block;
  width: 100%;
  &:before {
    content:'';
    height: 2px;
    width: 0;
    bottom: 0;
    left: 50%;
    position: absolute;
    background-color: @brand-primary;
    transition: all @duration @timing-function;
  }
}

.form-control:focus ~ .input-bar:before {
  width: 100%;
  left: 0;
}

.form-copy {
  font-weight: @bold;
}

// --------------------------------------------------
// 1.5 - #Form Control - Label
// --------------------------------------------------

.form-control-label {
  color: @text-color-light;
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: @regular;
  pointer-events: none;
  transition: all 0.2s @timing-function;
  position: absolute;
  left: 0;
  top: -12px;
}

// --------------------------------------------------
// 1.6 - #Form Control - Checkbox
// --------------------------------------------------

/**
 * @styles /scaffolding/_forms.less Checkbox
 *
 * @description Checkbox form-control
 * @demo demos/scaffolding/form_components/checkbox/demo.html
 */

.form-group-checkbox {
  user-select: none;
}

.form-group-checkbox-sml {
  margin-bottom: @spacer / 10;
  margin-top: 0;
}

// MODIFIERS
.form-control-checkbox {
  &:checked {
    + .form-control-label-checkbox {
      color: @text-color;
      .mp-icon-box-checked {
        opacity: 1;
        transform: scale(1);
        color: @brand-primary;
      }
      .mp-icon-box-empty {
        opacity: 0;
        transform: scale(0.5);
      }
    }
  }
}

.form-control-label-checkbox {
  position: relative;
  cursor: pointer;
  padding-left: @spacer / 1.5;
  color: @text-color-light;
  display: inline-block;

  .@{icon-prefix}-icon {
    font-size: @spacer / 2;
    position: absolute;
    top: 0;
    left: 0;
    transition: all @duration @timing-function;
    pointer-events: none;
  }

  .@{icon-prefix}-icon-box-empty {
    opacity: 1;
  }

  .@{icon-prefix}-icon-box-checked {
    opacity: 0;
    transform: scale(0.5);
  }
}

.form-control-label-copy {
  display: inline-block;
  vertical-align: middle;
  transition: color @duration @timing-function;
  margin-right: @spacer;
}

// --------------------------------------------------
// 1.7 - #Form Control - Radio
// --------------------------------------------------

/**
 * @styles Radio Radio
 *
 * @description Checkbox form-control
 * @demo demos/scaffolding/form_components/radio/demo.html
 */

.form-group-radio {
  position: relative;
  margin-bottom: @spacer / 15;
}

.form-group-radio-inline {
  display: flex;
}

// MODIFIERS
.form-control-radio {
  + .form-control-radio-label {
    cursor: pointer;
    margin-right: 0.25rem;
  }

  &:checked {
    + .form-control-radio-label .form-control-radio-span {
      &:after {
        background-color: @brand-primary;
        transform: scale(1);
      }
    }
  }

}

.form-control-radio-span {
  border: 2px solid @border-color;
  display:inline-block;
  width: @spacer / 3;
  height: @spacer / 3;
  margin-right: @spacer / 5;
  font-size: 0;
  vertical-align:middle;
  border-radius: 50%;
  position: relative;

  &:after {
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    top: 2px;
    left: 2px;
    border-radius: 50%;
    transform: scale(0.5);
    transition: all @duration @timing-function;
    @media @desktop {
      top: 3px;
      left: 3px;
    }
  }
}

.form-control-radio-label {
  vertical-align: middle;
}

// --------------------------------------------------
// 1.8 - #Form Control - Textarea
// --------------------------------------------------

/**
 * @styles text-area Text Area
 *
 * @description The primary text field style
 *
 * @demo demos/scaffolding/form_components/text_area/demo.html
 */

.form-control-textarea {
  resize: vertical;
  min-height: @spacer;
  padding: @spacer / 4 0;

  &:disabled {
    resize: none;
  }
}

// --------------------------------------------------
// 1.9 - #Form Control - Select
// --------------------------------------------------

/**
 * @styles Dropdown Dropdown
 *
 * @description Checkbox form-control
 * @demo demos/scaffolding/form_components/select_dropdown/demo.html
 */

.form-group-select {
  &:before {
    content: " ";
    position: absolute;
    top: 55%;
    right: 0;
    border-style: solid;
    border-color: transparent;
    height: 0;
    width: 0;
    pointer-events: none;
    border-top-color: @text-color-light;
    border-width: 5px;
    margin-left: -5px;
    transition: border-top-color @duration @timing-function;
  }

  &:hover {
    .form-control-select {
      color: @brand-primary;
    }

    &:before {
      border-top-color: @brand-primary;
    }
  }
}

.form-control-select {
  appearance: none;
  border-radius: 0;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 1px solid @border-color;
  transition: color @duration @timing-function;
  padding: @spacer / 6 0;;
  font-family: @font-family-base;
  background-color: transparent;
  width: 100%;
  padding-right: @spacer / 3;
  .text-overflow();
  color: @text-color-light;

  &:focus {
    outline: none;
    ~ .input-bar:before {
      width: 100%;
      left: 0;
    }
  }

  &[disabled] {
    opacity: @opacity-40;
    &:hover {
      color: @text-color;
    }
  }
}

.form-control-label-select {
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  font-family: @font-family-base;
  color: @text-color-light;
}

.form-control-label-select:after {
  content: ' ';
  position: absolute;
  top: 42%;
  right: 0;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 6px solid @text-color-light;
}

// --------------------------------------------------
// 1.10 - #Form Control - Application Type
// --------------------------------------------------

.radio-app-type {
  margin-bottom: 0;

  @media @desktop {
    margin-bottom: 1rem;
  }

  &.checkbox-radio {
    margin-left: 0;
  }

  .form-control-label-checkbox {
    width: 100%;
    height: auto;
    display: block;
    text-align: center;
    border: 1px solid @border-color;
    border-radius: @border-radius;
    transition: @duration;
    cursor: pointer;

    span {
      display: inline-block;
      vertical-align: middle;
      left: -1rem;
    }
  }

  .checkbox-radio-icon-wrap {
    position: relative;
    height: 1.3888888889rem;
    width: 1.388888889rem;
    top: 0;

    .mp-icon {
      fill: @brand-primary;
      width: 100%;
      height: 100%;
      transition: @duration @timing-function;
    }
  }

  .radio-header-wrap {
    margin-top: 1rem;
  }

  .body-copy-small {
    padding: 1rem 2rem 0 2rem;
  }

  .form-control-radio:checked:hover,
  .form-control-radio:hover + label,
  .form-control-radio:checked + label {
    border-color: @brand-primary;
    background-color: @brand-primary;
    color: white;

    .mp-icon {
      fill: white;
    }
  }
}

// --------------------------------------------------
// 1.11 - #Form Control - Addon
// --------------------------------------------------

.form-control-with-addon {
  padding-left: 1rem;
}

.input-addon {
  position: absolute;
  top: 0.9rem;
  color: @brand-secondary;
  font-weight: @bold;
  z-index: @zIndex-3--content-top;
}
