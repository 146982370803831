/*

    Step 4 - Checkout
    --------------------------------------------------

    Table of Contents
    --------------------------------------------------
    :: 0.0 - #StyleGuide Definition
    :: 1.0 - #Modifiers
    :: 1.1 - #Containers


*/

// --------------------------------------------------
// 0.0 - #Styleguide Definitions
// --------------------------------------------------


// --------------------------------------------------
// 1.0 - #Layout
// --------------------------------------------------

.checkout-policy-wrap {
  background: @gray-lightest;
  padding: @spacer / 2;
  border: 1px solid @border-color;

  .form-group-checkbox {
    border-bottom: 1px solid @border-color;
    padding-bottom: @spacer / 4;
  }
}

.checkout-renew-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: @spacer / 4;
  p {
    margin: 0;
  }
}

.checkout-billing-wrap {
  margin-top: 1.5rem;
}

.payment-options-wrap {
  position: relative;
}

.credit-card-icons {
  position: absolute;
  top: 0;
  right: 0;
}

// Don't have the label break to two lines on desktop-xl
.pay-offline {
  @media @desktop-lg {
    padding: 0;
  }
}

.renew-notice-copy {
  margin-top: 0.75rem;
  margin-bottom: 0;
}

// --------------------------------------------------
// 2.0 - #Section: Payment Options
// --------------------------------------------------

.payment-security-bar {
  background: @gray-lightest;
  height: 4rem;
  width: 100%;
  margin-top: -0.25rem;
}

.payment-security-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid @gray-light;
}

.icon-trust {
  height: 52px;
  margin: 0 0.5rem;
  @media @phone-xs-max {
    height: 45px;
  }
}

.payment-security-header {
  font-size: 0.833333334rem;
  font-weight: @bold;
  color: @gray-dark;
}

.payment-security-copy {
  font-size: 0.6666667rem;
  font-weight: @bold;
  color: @gray-dark;
}

.payment-options-wrap  {

  .label-checkbox-radio {
    font-size: 0.833333334rem;
  }

  .form-group.checkbox-radio {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .checkbox-radio-icon-wrap {
    top: -0.1rem;
  }

  .row {
    width: 100%;
  }

  .checkbox-radio-item-wrap {
    margin-right: 0;
  }

  @media @desktop-lg {
    .amex-container {
      .label-copy {
        padding-left: 76px;
        display: inline-block;
        position: absolute;
        left: 0;
        top: 0;
        line-height: 1em;

        .credit-card-icon {
          position: absolute;
          left: 32px;
          top: 6px;
        }
      }
    }
  }
}

.credit-card-icon {
  background: url('/static/img/checkout-image-sprite.png');
  background-position: 0 0;
  height: 22px;
  width: 36px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;

  &.mastercard {
    background-position: -47px 0;
  }

  &.amex {
    background: url('/static/img/amex.png');
  }

  &.discover {
    background: url('/static/img/discover.png');
  }
}

.payment-form-section {
  @media @desktop {
    margin-bottom: @spacer / 2;
  }
}

.payment-details-wrap {
  border: 1px solid @gray-light;
  padding: @spacerMobile / 2;

  @media @desktop {
    padding: @spacer;
  }
}

.payment-details-header {
  width: 100%;
  border-bottom: 1px solid @gray-light;
  margin-bottom: 2rem;
}

.payment-detail-item {
  display: flex;
  flex-direction: row;
  vertical-align: top;
  justify-content: space-between;
  margin-bottom: 1rem;
  span {
    font-weight: @bold;
  }
}

.payment-detail-label {
  margin-right: @spacerMobile;
}

.payment-detail-total {
  border-top: 1px solid @gray-darker;
  text-align: right;
  padding-top: 1.5rem;
}

.payment-section-top {
  border-top: 1px solid @border-color;
  padding-top: @spacer / 4;
  // Forgive me for this bad css but the payment page is
  // Bootstrap div mess
  margin: @spacer / 4 0.8rem 0 0.9rem;
}

.payment-section-bottom {
  border-bottom: 1px solid @border-color;
  padding-bottom: @spacer / 4;
}
