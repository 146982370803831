/*

    Header
    --------------------------------------------------

    Table of Contents
    --------------------------------------------------
    :: 1.0 - #Containers
    :: 1.1 - #Footer - List
    :: 1.2 - #Footer - List Item
    :: 1.3 - #Footer - List Item Link
    :: 1.4 -

*/

//--------------------------------------------------
// 1.0 - #Containers
//--------------------------------------------------

.footer {
  background: @gray-darker;
  width: 100%;
  padding: @spacerMobile * 1.5 0;
  @media @desktop {
    padding: @spacer / 2 0;
  }
}

//--------------------------------------------------
// 1.1 - #Footer - List
//--------------------------------------------------

.footer-list {
  flex-wrap: wrap;

  @media @desktop {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: @spacer / 4 auto 0 auto;
  }
}

.footer-list-sml {
  color: white;
}


//--------------------------------------------------
// 1.2 - #Footer - List Item
//--------------------------------------------------

.footer-list-item {
  list-style: none;
  margin-bottom: @spacerMobile / 2;

  @media @desktop {
    width: auto;
    margin: @spacer / 4 @spacer / 2 0;
  }
}

//--------------------------------------------------
// 1.3 - #Footer - List Item Link
//--------------------------------------------------

.footer-list-item-link {
  color: white;
  text-decoration: none;
  font-weight: @medium;
  font-size: @spacerMobile / 1.25;
  @media @desktop {
    font-size: @spacer / 2.5;
  }
}

.footer-list-sml-item-link {
  font-size: @spacerMobile / 1.875;
  @media @desktop {
    font-size: @spacer / 4.285714286;
  }
}

//--------------------------------------------------
// 1.4 - #Footer - Social Links
//--------------------------------------------------

.footer-social-wrap {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin: @spacerMobile 0;

  @media @desktop {
    margin: @spacer / 1.5 0;
  }
}

.footer-social-item {
  margin: 0 @spacer / 4;
  cursor: pointer;
  transition: all @duration @timing-function;
  font-size: @spacerMobile * 1.5;
  position: relative;
  width: @spacerMobile * 1.5;
  height: @spacerMobile * 1.5;

  &:before {
    content: " ";
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%,-50%,0);
    z-index: 0;
    width: 30px;
    height: 30px;

    @media @desktop {
      width: 30px;
      height: 30px;
    }

  }

  &:hover {
    opacity: @opacity-80;
  }

  @media @desktop {
    font-size: @spacer;
    width: @spacer;
    height: @spacer;
  }

  .@{icon-prefix}-icon {
    position: relative;
    z-index: 1;
  }
}
