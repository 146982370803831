//
// Utility classes
// --------------------------------------------------


// Floats
// -------------------------

.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}


// Toggling content
// -------------------------

// Note: Deprecated .hide in favor of .hidden or .sr-only (as appropriate) in v3.0.1
.hide {
  display: none !important;
}
.show {
  display: block !important;
}
.invisible {
  visibility: hidden;
}

// Hide from screenreaders and browsers
//
// Credit: HTML5 Boilerplate

.hidden {
  display: none !important;
}

.no-margin {
  margin: 0;
}

.no-top-margin {
  margin-top: 0;
}

.no-bottom-margin {
  margin-bottom: 0;
}

.no-top-border {
  border-top: 0 !important;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.mt-2 {
  margin-top: 2rem;
}

.mb-5 {
  margin-bottom: 5rem;
}

.pb-0 {
  padding-bottom: 0;
}
