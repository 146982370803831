/*

    Pages - Apply - Step 3 (Application Review)
    --------------------------------------------------

    Table of Contents
    --------------------------------------------------
    :: 1.0 - #Containers
    :: 1.1 - #Terms & Conditions
    :: 1.2 - #Overrides

*/

//--------------------------------------------------
// 1.0 - #Containers
//--------------------------------------------------

.app-review-header {
  background-color: @gray-lightest;
  padding: @spacer / 2 @spacer / 2;
  margin-top: @spacer / 2;
  margin-bottom: @spacer / 2;
  border-top: 1px solid @border-color;
  border-bottom: 1px solid @border-color;
}
.app-header-copy {
  margin: @spacer / 4 0 @spacer / 4 0;
}

.app-header-label-copy {
  font-size: @spacer / 3.333333333;
  font-weight: @bold;
  margin-bottom: 0;
}

.app-header-total-copy {
  font-size: @spacer / 1.05;
  font-weight: @bold;
  margin-top: 0;
  margin-bottom: 0;
}

.applicant-review-wrap {
  .form-control-label {
    .text-overflow();
    max-width: 100%;
  }
}

//--------------------------------------------------
// 1.1 - #Terms & Conditions
//--------------------------------------------------

.toc-wrap {
  border: 1px solid @border-color;
  height: @spacer * 4;
  overflow-y: auto;
  overflow-x: hidden;
  padding: @spacer / 2;

  p {
    font-size: @spacer / 4;
  }
}

//--------------------------------------------------
// 1.2 - #Overrides
//--------------------------------------------------

.sum-member {
  .text-overflow();
}
