.font-size(@font-size-sm, @font-size-md, @font-size-lg) {
  //font-size: @font-size-sm * 1px;
  font-size: (@font-size-sm/@remSm) * 1rem;

  @media @tablet {
    //font-size: @font-size-md * 1px;
    font-size: (@font-size-md/@remMd) * 1rem;
  }

  @media @desktop-lg {
    //font-size: @font-size-lg * 1px;
    font-size: (@font-size-lg/@remLg) * 1rem;
  }
}
