// 768px
@media @phone {

}

// 1024px
@media @tablet {
  html {
    font-size: @remMd * 1px;
  }

}

// 1280px;
@media @desktop {

}

// 1440px;
@media @desktop-lg {
  html {
    font-size: @remLg * 1px;
  }
}