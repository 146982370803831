/*

    Page - Equipment List Pop Up
    --------------------------------------------------

    Table of Contents
    --------------------------------------------------
    :: 1.0 - #Modifiers
    :: 1.1 - #Containers

*/

//--------------------------------------------------
// 1.0 - #Modifiers
//--------------------------------------------------

.upload-excel-form {
  .btn-icon {
    @media @desktop-xl {
      width: 60%;
    }
  }
}

//--------------------------------------------------
// 1.1 - #Containers
//--------------------------------------------------
