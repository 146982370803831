/*

    Globals
    --------------------------------------------------

    Table of Contents
    --------------------------------------------------
    :: 1.0 - React Loading State
    :: 1.1 -

*/

//--------------------------------------------------
// 1.0 - #React Loading State
//--------------------------------------------------

// Mixins
.animation(@animation) {
  animation: @animation;
}

.translate3d(@x; @y; @z) {
  transform: translate3d(@x, @y, @z);
}

// Universal container class for React
.js-react-container {

  min-height: 15vh;

  @media @desktop {
    min-height: 20vh;
  }

  // Only show when div is empty
  &:empty {
    .animation(fade @duration @timing-function);
    position: relative;

    &:before,
    &:after {
      content: "";
      position: absolute;
    }

    &:before {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }

    &:after {
      content: "";
      border-bottom: 4px solid @gray-light;
      border-left: 4px solid @gray-light;
      border-right: 4px solid @brand-primary;
      border-top: 4px solid @brand-primary;
      border-radius: 100%;
      height: 50px;
      width: 50px;
      position: absolute;
      z-index: @zIndex-1--content-lower;
      bottom: 175px;
      left: 50%;
      top: 50%;
      margin: -30px 0 0 -25px;
      .translate3d(-50%,0,0);
      .animation(spin 750ms infinite linear);
      backface-visibility: hidden;
    }
  }
}

// Animation
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
