/*

    LESS Template
    --------------------------------------------------

    Table of Contents
    --------------------------------------------------
    :: 0.0 - #Stylguide
    :: 1.0 - #Modifiers
    :: 1.1 - #Nav
    :: 1.2 - #Nav - List
    :: 1.3 - #Nav - List Item
    :: 1.4 - #Nav - List Item Link

*/


// --------------------------------------------------
// 0.0 - #Styleguide
// --------------------------------------------------

/**
  * @stylesheet nav-desktop.less Desktop Nav
  * @parent Styles.scaffolding 1
  * @description
  * listed below.
**/


/**
 * @styles navigation Desktop Navigation
 *
 * @description Default state
 * @demo demos/scaffolding/desktop-nav/demo.html
 */

// --------------------------------------------------
// 1.0 - #Modifiers
// --------------------------------------------------

.nav-fixed {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.nav-open {
  .nav {
    background-color: transparent !important;
    box-shadow: none !important;
  }
  .nav-line {
    background-color: white;
  }
}

// --------------------------------------------------
// 1.1 - #Nav
// --------------------------------------------------

.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: @spacerMobile / 4 @spacerMobile;
  background-color: white;
  z-index: @zIndex-3--content-top;
  height: @spacerMobile * 2;
  min-height: @spacerMobile * 2;
  color: @text-color;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: all @duration @timing-function;
  will-change: transform;

  @media @desktop {
    height: @spacer * 2;
    min-height: @spacer * 2;
    padding: @spacer / 4 @spacer / 2;
  }
}

.nav-down {
  transform: translate3d(0,0,0);
  box-shadow: 0 0 16px rgba(0,0,0,0.1), 0 2px 6px rgba(0,0,0,0.15);
}

.nav-up {
  transform: translate3d(0,-100%,0);
}

.nav-top {
  box-shadow: none;
}

.nav-logo {
  display: block;
  transition: opacity @duration @timing-function;
  &:hover {
    opacity: @opacity-50;
  }

  .img-logo {
    height: @spacerMobile * 2;

    @media @desktop {
      height: @spacer * 2;
    }
  }
}

// --------------------------------------------------
// 1.1 - #Nav - List
// --------------------------------------------------

.nav-list {
  margin-top: 0;
  font-family: @font-family-base;
  font-weight: 700;
}

// --------------------------------------------------
// 1.2 - #Nav - List Item
// --------------------------------------------------

.nav-list-item {
  white-space: nowrap;
  margin-bottom: 0;

  @media @desktop {
    &.active {
      color: @brand-primary;
    }
  }

}

// --------------------------------------------------
// 1.3 - #Nav - List Item Link
// --------------------------------------------------

.nav-list-item-link {
  font-size: @spacerMobile / 1.5;
  padding: 0 @spacerMobile / 4;
  text-transform: uppercase;
  text-decoration: none;
  display: block;
  transition: all @duration @timing-function;
  cursor: pointer;
  color: inherit;

  &:hover {
    color: @brand-primary;
  }

  @media @desktop {
    font-size: @spacer / 4;
    padding: 0 @spacer / 4;
  }

}

.nav-list-item-btn {
  margin: 0 0 0 @spacer / 2;
  padding: 0;
  background-color: @brand-primary;
  line-height: 2.133333333;
  border-radius: @border-radius;
  box-shadow: @z-depth-1;
  color: white;
  overflow: hidden;

  @media @desktop {

    &:hover {
      color: white;
      background-color: @link-hover-color;
    }

  }

}
