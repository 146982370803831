/*

    Table - FAQ
    --------------------------------------------------

    Table of Contents
    --------------------------------------------------
    :: 1.0 - #Containers
    :: 1.1 - #Type
    :: 1.2 -

*/

//--------------------------------------------------
// 1.0 - #Containers
//--------------------------------------------------

.table-row-faq {
  border-top: 1px solid @border-color;
  background-color: white;

  .table-row {
    border-bottom: 1px solid transparent;
    padding: @spacerMobile / 2 0
  }

  .@{icon-prefix}-icon {
    font-size: @spacer / 2.5;
    color: @brand-primary;
    position: absolute;
    top: 50%;
    left: @spacer / 2;
    transform: translate3d(-50%,-50%,0);
  }

}

.table-row-collapse-faq {
  background-color: white;
  border-bottom: 0;
  padding-left: 0;

  @media @desktop {
    padding-left: 0;
  }

  .table-cell-question {
    padding-top: @spacerMobile / 2;
  }

}

.table-cell-question {
  position: relative;
  padding-right: 0;
  padding-left: @spacerMobile * 2;
  white-space: normal;

  @media @desktop {
    padding-left: @spacer;
    padding-right: @spacer / 2;
  }

  p:first-child {
    margin-top: 0;
  }
}

.collapse {
  display: none;
  &.in {
    display: block;
  }
}