/*

    Table - Summary
    --------------------------------------------------

    Table of Contents
    --------------------------------------------------
    :: 1.0 - #Modifiers
    :: 1.1 - #Containers
    :: 1.2 - #Table Summary - Columns

*/

//--------------------------------------------------
// 1.0 - #Modifiers
//--------------------------------------------------

.table-container-summary-wrap {
  @media @desktop {
    max-height: @spacer * 11.583333333;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.table-summary {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  // Overrides annoying height issue with flexbox in IE for anything < desktop viewports.
  @media @desktop-max {
    max-height: none !important;
  }

  // Fixes annoying height issue with flexbox in IE.
  @media @isIE {
    max-height: @spacer * 11.583333333;
  }

  .table-head {
    position: static;
  }

  .table-body {
    padding-top: 0;
    position: relative;
    z-index: @zIndex-1--content-lower;
  }
}

//--------------------------------------------------
// 1.1 - #Containers
//--------------------------------------------------

//--------------------------------------------------
// 1.2 - #Table Summary - Columns
//--------------------------------------------------

@tableCellWidthSumType:         10%;
@tableCellWidthSumModel:        30%;
@tableCellWidthSumDesc:         30%;
@tableCellWidthSumSerial:       15%;
@tableCellWidthSumValue:        15%;

.table-cell-sum-type {
  width: 20%;
  @media @desktop {
    width: @tableCellWidthSumType;
  }
}
.table-cell-sum-model {
  width: 80%;
  @media @desktop {
    width: @tableCellWidthSumModel;
  }
}

.table-cell-sum-desc {
  width: @tableCellWidthSumDesc;
  .text-overflow();
  @media @desktop-max {
    display: none;
  }
}
.table-cell-sum-serial {
  width: @tableCellWidthSumSerial;
  .text-overflow();
  @media @desktop-max {
    display: none;
  }
}
.table-cell-sum-value {
  width: @tableCellWidthSumValue;
  text-align: right;
}

.table-cell-serial {
  @media @phone-xs-max {
    width: 100%;
  }
}
