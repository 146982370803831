/*

    Step Indicator
    --------------------------------------------------

    Table of Contents
    --------------------------------------------------
    :: 1.0 - Modifiers
    :: 1.1 - Containers

*/

// --------------------------------------------------
// 1.0 - Modifiers
// --------------------------------------------------

.step-1-active {

  .step-indicator-progress {
    .v-hidden();
  }

  .step-icon-wrap-1 {
    .v-visible();
    .step-icon-pin {
      .v-visible();
    }
  }

}

.step-2-active {

  .step-indicator-progress {
    // For IE, which doesn't support CSS width changes on a <rect> element
    stroke-dasharray: 310;
    stroke-dashoffset: 190;
    @media @desktop {
      stroke-dasharray: 560;
      stroke-dashoffset: 330;
    }
  }

  .step-icon-wrap-1,
  .step-icon-wrap-2 {
    .v-visible();
  }

  .step-icon-wrap-1 {
    pointer-events: all;
    .step-icon-check {
      .v-visible();
    }
  }

  .step-icon-wrap-2 {
    .step-icon-pin {
      .v-visible();
    }
  }

}

.step-3-active {

  .step-indicator-progress {
    // For IE, which doesn't support CSS width changes on a <rect> element
    stroke-dasharray: 560;
    stroke-dashoffset: 330;

    @media @desktop {
      stroke-dasharray: 810;
    }
  }

  .step-icon-wrap-1,
  .step-icon-wrap-2,
  .step-icon-wrap-3 {
    .v-visible();
  }

  .step-icon-wrap-1,
  .step-icon-wrap-2 {
    pointer-events: all;
    .step-icon-check {
      .v-visible();
    }
  }

  .step-icon-wrap-3 {
    .step-icon-pin {
      .v-visible();
    }
  }

}

.step-4-active {

  .step-indicator-progress {
    // For IE, which doesn't support CSS width changes on a <rect> element
      stroke-dasharray: 660;
      stroke-dashoffset: 330;
    @media @desktop {
      stroke-dasharray: 1080;
    }
  }

  .step-icon-wrap-1,
  .step-icon-wrap-2,
  .step-icon-wrap-4,
  .step-icon-wrap-3 {
    .v-visible();
  }

  .step-icon-wrap-1,
  .step-icon-wrap-2,
  .step-icon-wrap-3 {
    pointer-events: all;
    .step-icon-check {
      .v-visible();
    }
  }

  .step-icon-wrap-4 {
    .step-icon-pin {
      .v-visible();
    }
  }
}

// --------------------------------------------------
// 1.1 - Containers
// --------------------------------------------------

.step-indicator-wrap {
  transform: translate3d(0,-50%,0);
  padding: 0 @spacerMobile / 2;

  @media @desktop {
    transform: translate3d(0,0,0);
    margin-top: -@spacer / 1.875;
    padding: 0;
  }

  @media @desktop-lg {
    margin-top: -@spacer / 1.395348837;
  }
}

// --------------------------------------------------
// 1.1 - Step Indicator
// --------------------------------------------------

.step-indicator-svg {
  display: block;
  margin: 0 auto;
  width: 100%;

  @media @tablet {
    width: 700px;
  }

  @media @desktop {
    width: auto;
  }
}

.step-item-wrap {
  text-decoration: none;
  pointer-events: none;
  &:hover {
    .step-icon-bg {
      fill: @brand-secondary;
    }
  }
}

.step-icon-wrap,
.step-icon-pin,
.step-icon-check {
  .v-hidden();
}

.step-icon-bg {
  transition: all @duration @timing-function;
}

.step-indicator-progress {
  width: 0;
  fill: @brand-primary;
  transition: all @duration @timing-function;
  height: 4px;
}
