// Forms
// --------------------------------------------------

/*
   Table of Contents
   --------------------------------------------------
   :: 1.0 - #Styleguide Definitions

   :: 1.1 - #Error Message
   :: 1.2 - #Error Notification

*/

// --------------------------------------------------
// 1.0 - #Styleguide Definitions
// --------------------------------------------------

/**
  * @parent Styles.components 4
  * @stylesheet /scaffolding/forms.less Forms
  * @description
  * All form related definitions here
  *
**/


/**
 * @styles error_bottom_message Error Message at Bottom of Application Page
 *
 * @description Error message displayed at center/bottom of application pages.
 *
 * @demo demos/components/error_notification/demo.html
 */


// --------------------------------------------------
// 1.1 - #Error Message
// --------------------------------------------------

.text-error {
  color: @brand-danger;

  @media @desktop-max {
    font-size: @spacer / 5;
    font-weight: @regular;
  }
}

// Used to align vertically when error and submit button
// share two adjacent columns
.error-vertical-align {
  margin: @spacer / 3 0 0;
}

// --------------------------------------------------
// 1.2 - #Error Notification
// --------------------------------------------------

.error-notification {
  text-align: center;
  width: auto;
  margin-bottom: @spacer / 2;
  color: @brand-danger;
  font-size: @spacer / 5;
  order: 1;

  @media @desktop {
    font-weight: @bold;
    color: @text-color;
    z-index: @zIndex-2--content;
    border-radius: @border-radius;
    background-color: @gray-lightest;
    border: 2px solid @brand-danger;
    padding: @spacer / 4 @spacer / 2;
    top: 1rem;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    margin-bottom: 1rem;
    font-size: 0.75rem;
    order: 0;

    &:before {
      content: '';
      position: absolute;
      top: -8px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 6px solid @brand-danger;
    }
  }

  @media @desktop-lg {
    font-size: 1rem;
  }
}
