/*

    Policy Cards
    --------------------------------------------------

    Table of Contents
    --------------------------------------------------
    :: 0.0 - #Styleguid Definition
    :: 1.0 - #Modifiers
    :: 1.1 - #Containers
    :: 1.2 - #Card - Notification
    :: 1.3 - #Card - Header
    :: 1.4 - #Card - Body
    :: 1.5 - #Card - Footer
    :: 1.6 - #Card - Type


*/

//--------------------------------------------------
// 0.0 - #Styleguide Definition
//--------------------------------------------------

/**
  * @stylesheet policy-card-master Policy Cards
  * @parent Styles.components 4
  * @description
  * Cards used for polices and receipts
  **/


/**
 * @styles policy-card Policy Card
 *
 * @description Policy Card
 * @demo demos/components/policy-cards/policy-card/demo.html
*/

//--------------------------------------------------
// 1.0 - #Modifiers
//--------------------------------------------------

//--------------------------------------------------
// 1.1 - #Containers
//--------------------------------------------------

.card {
  display: block;
  min-height: 300px;
  border-radius: @border-radius;
  margin: @spacer / 2 0;
  box-shadow: @z-depth-1;
}

//--------------------------------------------------
// 1.2 - #Card - Notification
//--------------------------------------------------

.card-notification {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: @brand-danger-alpha;
  border-top-left-radius: @border-radius;
  border-top-right-radius: @border-radius;
  padding: @spacerMobile / 2;

  @media @desktop {
    padding: 0 @spacer / 2;
    flex-direction: row;
    min-height: 4rem;
    align-items: center;
  }

  .@{icon-prefix}-icon {
    color: @brand-danger;
    margin-right: @spacer / 4;
    position: relative;
    top: 6px;
  }

  .btn {
    margin-top: 0;
  }

}

//--------------------------------------------------
// 1.3 - #Card - Header
//--------------------------------------------------

.card-header {
  display: flex;
  flex-direction: column-reverse;

  justify-content: space-between;
  margin-bottom: @spacer / 2;
  padding: @spacer / 2 @spacer / 2;
  border-bottom: 1px solid @border-color-light;

  @media @desktop {
    flex-direction: row;
  }

  .btn {
    margin-top: 0;
  }

  .btn-dropdown {
    padding: 0;
  }

  .btn-dropdown-list {
    top: auto;
  }

}

.card-title {
  margin-top: 0;
  margin-bottom: 0;
}

//--------------------------------------------------
// 1.4 - #Card - Body
//--------------------------------------------------

.card-body {
  padding: 0 @spacer / 2 @spacer / 2;

  .progress {
    margin-bottom: @spacer / 20;
  }
}

//--------------------------------------------------
// 1.5 - #Card - Footer
//--------------------------------------------------

.card-footer {
  border-top: 1px solid @border-color-light;
  padding: @spacer / 2.5 @spacer / 2;
  background-color: @gray-lightest;
  border-bottom-left-radius: @border-radius;
  border-bottom-right-radius: @border-radius;

  .btn {
    margin-top: 0;
  }

}

//--------------------------------------------------
// 1.5 - #Card - Type
//--------------------------------------------------

.card-label,
.card-copy {
  font-size: @spacer / 4;
}

.card-label {
  margin-top: 0;
  margin-bottom: 0;
}

.card-copy {
  margin-top: 0;
  margin-bottom: @spacer / 2;
  color: @text-color-light;
}