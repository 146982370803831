/*

    Header - Extra Small
    --------------------------------------------------

    Table of Contents
    --------------------------------------------------
    :: 1.0 - #Containers
    :: 1.1 - #Type
    :: 1.2 -

*/

//--------------------------------------------------
// 1.0 - #Containers
//--------------------------------------------------

.page-header-xs {
  background-color: @brand-secondary;
  border-top: @spacer / 6 solid @brand-primary;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  height: @spacerMobile * 6;

  @media @desktop {
    height: @spacer * 4;
    padding: 0;
    text-align: left;
  }

  .page-header-title {
    color: inherit;
    margin: 0;
  }
}

.page-header-welcome {

  background: @gray-lightest;
  text-align: center;
  color: @text-color;
  border: 1px solid @border-color-light;
  padding: @spacer / 2 0;
  display: block;

  .@{icon-prefix}-icon {
    font-size: @spacer;
    color: @brand-primary;
    margin-bottom: @spacer / 2;
  }

}