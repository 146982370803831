/*

    Modal
    --------------------------------------------------

    Table of Contents
    --------------------------------------------------
    :: 1.0 - #Modifiers
    :: 1.1 - #Containers
    :: 1.2 - #Modal - Dialog
    :: 1.3 - #Modal - Content
    :: 1.4 - #Modal - Header
    :: 1.5 - #Modal - Body
    :: 1.6 - #Modal - Type
    :: 1.7 - #Modal - Footer
    :: 1.8 - #Modal - Backdrop
    :: 1.9 - #Modal - Close
    :: 2.0 - #Imports

*/

//--------------------------------------------------
// 1.0 - #Modifiers
//--------------------------------------------------

.modal-sml {

  .modal-content {
    width: 100%;
    @media @desktop {
      width: @spacer * 8;
    }
  }

  .modal-footer {
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    align-items: center;

    @media @desktop {
      flex-direction: row;
    }
  }

  .link-sml {
    display: block;
    margin-top: @spacerMobile / 2;
    @media @desktop {
      margin-top: 0;
    }
  }

}

//--------------------------------------------------
// 1.1 - #Containers
//--------------------------------------------------

.modal-open {
  overflow: hidden;
  .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: @zIndex-1001--modal;
  display: none;
  overflow: hidden;
  outline: 0;

  &.in {
    .modal-content {
     .v-visible();
      transform: scale(1);
    }
  }
}

//--------------------------------------------------
// 1.2 - #Modal - Dialog
//--------------------------------------------------

.modal-dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: @spacerMobile;

  @media @desktop {
    padding: 0;
    height: 100%;
  }
}

//--------------------------------------------------
// 1.3 - #Modal - Content
//--------------------------------------------------

.modal-content {
  background-color: white;
  position: relative;
  box-shadow: @z-depth-3;
  transition: all @duration @timing-function;
  transform: scale(0.95);
  .v-hidden();

  @media @desktop {
    width: @spacer * 12;
  }
}

//--------------------------------------------------
// 1.4 - #Modal - Header
//--------------------------------------------------

.modal-header {
  padding: @spacerMobile / 2 @spacerMobile;
  @media @desktop {
    padding: @spacer / 2;
  }
}


//--------------------------------------------------
// 1.5 - #Modal - Body
//--------------------------------------------------

.modal-body {
  padding: @spacerMobile / 2 @spacerMobile @spacerMobile / 2 @spacerMobile;
  @media @desktop {
    padding: @spacer / 4 @spacer / 2 @spacer / 4 @spacer / 2;
  }
}

//--------------------------------------------------
// 1.6 - #Modal - Type
//--------------------------------------------------

.modal-title {
  margin-top: 0;
  font-size: @spacer / 2;
}

.modal-copy {
  margin-bottom: 0;
}

//--------------------------------------------------
// 1.7 - #Modal - Footer
//--------------------------------------------------

.modal-footer {
  padding: @spacer / 2 @spacer / 2 @spacer / 2 @spacer / 2;
  background-color: @gray-lightest;
  border-top: 1px solid @border-color-light;
  text-align: center;
  font-size: 0;

  .btn {
    margin-top: 0;
    margin-bottom: 0;
  }
}

//--------------------------------------------------
// 1.8 - #Modal - Backdrop
//--------------------------------------------------

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: @zIndex-1000--modal-background;
  background-color: @modal-background;
  transition: all @duration @timing-function;

  .v-hidden();

  &.in {
    .v-visible();
  }

}

//--------------------------------------------------
// 1.9 - #Modal - Close
//--------------------------------------------------

.btn-close {
  font-size: @spacer / 1.5;
  color: @brand-primary;
  transition: all @duration @timing-function;
  position: absolute;
  top: @spacer / 4;
  right: @spacer / 4.5;
  z-index: @zIndex-1--content-lower;
  background-color: transparent;
  border: 0;
  padding: 0;

  &:hover {
    color: @brand-secondary;
  }
}

//--------------------------------------------------
// 2.0 - #Imports
//--------------------------------------------------

//@import "_modal-videoplayer.less";
