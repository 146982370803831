/*

    Tooltips
    --------------------------------------------------

    Table of Contents
    --------------------------------------------------
    :: 1.0 - Global Layout
    :: 1.1 - States

*/

// --------------------------------------------------
// 1.0 - Containers
// --------------------------------------------------

.icon-tooltip {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-left: 0.111111112rem;

  .mp-icon {
    width: 100%;
    height: 100%;
    fill: @brand-primary;
    cursor: pointer;
  }
}

.tooltip {
  position: absolute;
  z-index: @zIndex-2--content;
  font-size: @spacer / 4;
  display: block;
  opacity: 0;
  &.in     { opacity: 1; }
  &.top    { margin-top:  -3px; padding: @tooltip-arrow-width 0; }
  &.right  { margin-left:  3px; padding: 0 @tooltip-arrow-width; }
  &.bottom { margin-top:   3px; padding: @tooltip-arrow-width 0; }
  &.left   { margin-left: -3px; padding: 0 @tooltip-arrow-width; }
}

.tooltip-inner {
  max-width: @spacer * 8;
  padding: @spacer / 4;
  color: @text-color;
  line-height: 1.5;
  font-size: @spacer / 4;
  text-align: center;
  background-color: white;
  border-radius: @border-radius;
  box-shadow: @z-depth-1;
  font-weight: @regular;
  font-family: @font-family-base;
}

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.tooltip {
  &.top .tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -@tooltip-arrow-width;
    border-width: @tooltip-arrow-width @tooltip-arrow-width 0;
    border-top-color: @tooltip-arrow-color;
  }
  &.right .tooltip-arrow {
    top: 50%;
    left: 0;
    margin-top: -@tooltip-arrow-width;
    border-width: @tooltip-arrow-width @tooltip-arrow-width @tooltip-arrow-width 0;
    border-right-color: @tooltip-arrow-color;
  }
  &.left .tooltip-arrow {
    top: 50%;
    right: 0;
    margin-top: -@tooltip-arrow-width;
    border-width: @tooltip-arrow-width 0 @tooltip-arrow-width @tooltip-arrow-width;
    border-left-color: @tooltip-arrow-color;
  }
  &.bottom .tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -@tooltip-arrow-width;
    border-width: 0 @tooltip-arrow-width @tooltip-arrow-width;
    border-bottom-color: @tooltip-arrow-color;
  }
}

